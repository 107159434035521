import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { shape, string, oneOf, arrayOf, bool, number } from 'prop-types';
import { deRegistrationInformationStatus } from '~app/enums';

export const GET_ORDER = gql`
  query order($input: GetOrderRequest!) {
    order: getOrder(input: $input)
  }
`;

export const TOW_BAR_PART_NR = 'A00463';
export const MUDFLAP_PART_NR_LIST = ['A01395', 'A01426'];

export const TOW_BAR_SALES_ITEM_ID = 133;

export interface GetOrderResponse {
  order: string;
}

export interface GetOrderParams {
  input: { orderNumber: string };
}

const orderlinePropType = shape({
  orderline_id: number,
  created_at: string,
  updated_at: string,
  orderline_status: oneOf<OrderlineStatus>([
    'pending',
    'confirmed',
    'returned',
    'delivered',
    'cancelled',
    'ordered',
    'ready_for_delivery',
    'delivery_planning',
    'shipped'
  ]),
  unit_price: number,
  unit_vat: number,
  vat_rate: number,
  delivery_date: string,
  orderline_cancellation_reason: string,
  orderline_cancellation_note: string,
  orderline_return_reason: string,
  orderline_return_note: string,
  orderline_type: oneOf(['ConsumerCar', 'Extra']),
  delivery_address: shape({
    address_line_1: string,
    da_address_line_2: string,
    da_zip_code: string,
    da_city: string,
    da_district: string,
    da_province: string,
    da_country_code: string
  }),
  car_configuration: shape({
    configuration_id: string,
    model: string,
    upholstery: string,
    colour: string,
    wheels_rims: string,
    performance: string,
    package_codes: arrayOf(string),
    exterior_details_brightworks: string,
    pno_34: string,
    exterior_image_url: string,
    specifications: arrayOf(
      shape({
        label: string,
        value: string
      })
    ),
    dimensions: arrayOf(
      shape({
        label: string,
        value: string
      })
    )
  }),
  car_delivery_preferencies: shape({
    delivery_option: string,
    has_insurance: bool,
    personal_id_number: string
  }),
  car_factory_order: shape({
    meta_order_number: string,
    factory_order_number: string,
    vin: string,
    chassis_number: string,
    actual_brand_status_point: string,
    actual_vista_status_point: string,
    car_factory_order_statuses: arrayOf(
      shape({
        common_vista_status_point: string,
        common_vista_status_timestamp: string
      })
    ),
    wltp_values: shape({
      wltp_weighted_combined_co2: string,
      wltp_co2_unit: string,
      wltp_elec_energy_consumption: string,
      wltp_elec_energy_unit: string,
      wltp_elec_range: string,
      wltp_elec_range_unit: string
    }),
    factory_code: string,
    last_day_for_specification_change_date: string,
    planned_factory_complete_day: string,
    accepted_planned_build_date: string,
    accepted_planned_delivery_date: string,
    current_planned_build_date: string,
    current_planned_delivery_date: string,
    current_dealer_delivery_date: string,
    pds_date: string
  }),
  part_nr: string,
  extra_orderline_type: string,
  extra_product_type: oneOf([
    'Winter Wheels',
    'Charging cable',
    'Tire Hotel',
    'Child seat',
    'Mat',
    'Service Contract',
    'Load carrier',
    'Snow chains',
    'Auto sock',
    'Towbar',
    'Tax'
  ]),
  name: string,
  local_name: string,
  quantity: number,
  package_id: string,
  package_name: string,
  extra_delivery_method: oneOf(['home_delivery', 'paid_home_delivery', 'other_partner']),
  workorder: shape({
    workorder_id: string,
    workorder_link: string,
    workorder_creation_date: string,
    customer_contact: string,
    tire_hotel_location: string,
    delivery_date: string
  }),
  extra_delivery_preferencies: shape({
    wallboxinstallation_delivery_preferencies: shape({
      installation_at: string,
      installation_partner: string,
      pre_inspection_at: string
    }),
    tirehotel_delivery_preferencies: shape({
      transport_preference: string,
      preferred_location: string,
      preferred_location_name: string,
      preferred_parma_code: string,
      partner_id: string
    }),
    wintertire_delivery_preferencies: shape({
      wants_together_with_car: bool,
      wants_transport_from_dealer: string
    })
  })
});

export type OrderlineStatus =
  | 'pending'
  | 'confirmed'
  | 'returned'
  | 'delivered'
  | 'cancelled'
  | 'ordered'
  | 'delivery_planning'
  | 'ready_for_delivery'
  | 'shipped';

export interface OrderLine {
  orderline_id?: number;
  created_at?: string | null;
  updated_at?: string | null;
  orderline_status?: OrderlineStatus;
  unit_price?: number;
  unit_vat?: number;
  vat_rate?: number;
  delivery_date?: string | null;
  orderline_cancellation_reason?: string | null;
  orderline_cancellation_note?: string | null;
  orderline_return_reason?: string | null;
  orderline_return_note?: string | null;
  orderline_type?: 'ConsumerCar' | 'Extra';
  // ConsumerCar
  delivery_address?: {
    // ConsumerCar
    da_address_line_1?: string | null;
    da_address_line_2?: string | null;
    da_zip_code?: string | null;
    da_city?: string | null;
    da_district?: string | null;
    da_province?: string | null;
    da_country_code?: string | null;
  };
  car_configuration?: {
    // ConsumerCar
    configuration_id?: string | null;
    model?: string | null;
    model_year?: string | null;
    upholstery?: string | null;
    colour?: string | null;
    wheels_rims?: string | null;
    performance?: string | null;
    package_codes?: Array<string>;
    packages?: Array<{
      code?: string | null;
      name?: string | null;
    }>;
    additional_upgrades?: Array<{
      code?: string | null;
      name?: string | null;
    }>;
    engine?: string | null;
    exterior_details_brightworks?: string | null;
    pno_34?: string | null;
    specifications?: Array<{
      label?: string | null;
      value?: string | null;
    }>;
    dimensions?: Array<{
      label?: string | null;
      value?: string | null;
    }>;
    edition?: string | null;
    exterior_image_url?: string | null;
  };
  car_delivery_preferencies?: {
    // ConsumerCar
    delivery_option?: string | null;
    has_insurance?: boolean | null; // TODO boolean or string?
    personal_id_number?: string | null;
  };
  car_factory_order?: {
    // ConsumerCar
    meta_order_number?: string | null;
    factory_order_number?: string | null;
    vin?: string | null;
    chassis_number?: string | null;
    actual_brand_status_point?: string | null;
    actual_vista_status_point?: string | null;
    car_factory_order_statuses?: Array<{
      common_vista_status_point?: string | null;
      common_vista_status_timestamp?: string | null;
    }>;
    wltp_values?: {
      // ConsumerCar
      wltp_weighted_combined_co2?: string | null;
      wltp_co2_unit?: string | null;
      wltp_elec_energy_consumption?: string | null;
      wltp_elec_energy_unit?: string | null;
      wltp_elec_range?: string | null;
      wltp_elec_range_unit?: string | null;
    };
    factory_code?: string | null; // ConsumerCar
    last_day_for_specification_change_date?: string | null; // ConsumerCar
    planned_factory_complete_day?: string | null; // ConsumerCar
    accepted_planned_build_date?: string | null; // ConsumerCar
    accepted_planned_delivery_date?: string | null; // ConsumerCar
    current_planned_build_date?: string | null; // ConsumerCar
    current_planned_delivery_date?: string | null; // ConsumerCar
    current_dealer_delivery_date?: string | null; // ConsumerCar
    pds_date?: string | null; // ConsumerCar
  };
  // Extras
  part_nr?: string | null; // Extras
  extra_orderline_type?: string | null; // Extras
  extra_product_type?: // Extras
  | 'Winter Wheels'
    | 'Charging cable'
    | 'Tire Hotel'
    | 'Child seat'
    | 'Mat'
    | 'Service Contract'
    | 'Load carrier'
    | 'Snow chains'
    | 'Auto sock'
    | 'Towbar'
    | 'Tax' // Told in slack channel that this is valid, (not part of docs)
    | null; // Is null okey?
  name?: string | null; // Extras
  local_name?: string | null; // Extras
  quantity?: number; // Extras
  package_id?: string | null; // Extras
  package_name?: string | null; // Extras
  extra_delivery_method?: 'home_delivery' | 'paid_home_delivery' | 'other_partner' | null; // null means 'with the cars' // Extras
  workorder?: {
    // Extras
    workorder_id?: string | null;
    workorder_link?: string | null;
    workorder_creation_date?: string | null;
    customer_contact?: string | null;
    tire_hotel_location?: string | null;
    delivery_date?: string | null;
  };
  extra_delivery_preferencies?: {
    // Extras
    wallboxinstallation_delivery_preferencies?: {
      installation_at?: string | null;
      installation_partner?: string | null;
      pre_inspection_at?: string | null;
    };
    tirehotel_delivery_preferencies?: {
      transport_preference?: string | null;
      preferred_location?: string | null;
      preferred_location_name?: string | null;
      preferred_parma_code?: string | null;
      partner_id?: string | null;
    };
    wintertire_delivery_preferencies?: {
      wants_together_with_car?: boolean | null;
      wants_transport_from_dealer?: string | null;
    };
  };
}

export interface Partner {
  capabilities?: Array<string>;
  parma_code?: string | null;
  location_code?: string | null;
  partner_id?: string | null;
  name?: string | null;
  address_line_1?: string | null;
  zip_code?: string | null;
  city?: string | null;
  country_code?: string | null;
  phone?: string | null;
}

export interface OrderContract {
  data?: {
    order_no?: string | null;
    external_order_no?: string | null;
    market?: string | null;
    iso_language_code?: string | null;
    order_type?: 'pre-order' | 'order' | 'extras-order' | 'fleet' | 'remarketed';
    sales_type?: 'B2B' | 'B2C';
    order_state?:
      | 'pending'
      | 'confirmed'
      | 'delivery_planning'
      | 'delivered'
      | 'cancelled'
      | 'converted'
      | 'pending_return'
      | 'returned'
      | 'partially_delivered'
      | 'shipped';
    lock_state?: 'unlocked' | 'locked' | 'manually_unlocked';
    order_to_delivery_flow?: string | null;
    logistic_flow?: string | null;
    delivered_together_with?: string | null;
    preorder_id?: string | null;
    related_order_id?: string | null;
    related_extra_orders?: Array<string> | null;
    purchasing_order_number?: string | null;
    importer?: string | null;
    ordering_dealer?: string | null;
    destination?: string | null;
    selling_dealer?: string | null; // WILL BE REMOVED 2020-10-01
    delivering_dealer?: string | null; // WILL BE REMOVED 2020-10-01
    order_url?: string | null;
    terms_and_conditions_url?: string | null;
    order_cancellation_reason?: string | null;
    order_cancellation_note?: string | null;
    order_created_at?: string | null;
    order_modified_at?: string | null;
    deliveryAcceptanceComment?: string | null;
    // WILL BE REMOVED 2020-10-01
    roles?: Array<{
      type?: string | null;
      cdb_id?: string | null;
      salesforce_id?: string | null;
    }>;
    fleet?: {
      operator_id?: string | null;
      case_id?: string | null;
      reference_number?: string | null;
      external_order_date?: string | null;
      order_url?: string | null;
      parent_case_id?: string | null;
    };
    fleet_management_company_data?: {
      organization_number?: string | null;
      name?: string | null;
      cdb_id?: string | null;
      roles?: Array<string> | null;
    };
    ev_subsidy_information?: {
      ev_subsidy_status?: string | null;
      ev_subsidy_allocation_date?: string | null;
      ev_subsidy_amount?: string | null;
      ev_subsidy_confirmation_date?: string | null;
      ev_subsidy_cancellation_date?: string | null;
    };
    customer_data?: {
      cdb_id?: string | null;
      cdb_market?: string | null;
      polestar_id?: string | null;
      salesforce_id?: string | null;
      consumer_type?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      date_of_birth?: string | null;
      ssn?: string | null;
      address_line_1?: string | null;
      address_line_2?: string | null;
      zip_code?: string | null;
      city?: string | null;
      district?: string | null;
      province?: string | null;
      country_code?: string | null;
      national_id?: string | null;
      language?: string | null;
      email?: string | null;
      phone?: string | null;
      marketing_consented_at?: string | null;
      created_at?: string | null;
      modified_at?: string | null;
      b2b_consumer?: {
        registered_company_name?: string | null;
        company_vat_num?: string | null;
      };
    };
    person_data?: Array<{
      roles?: Array<string>;
      cdb_id?: string | null;
      cdb_market?: string | null;
      polestar_id?: string | null;
      salesforce_id?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      date_of_birth?: string | null;
      address_line_1?: string | null;
      address_line_2?: string | null;
      zip_code?: string | null;
      city?: string | null;
      district?: string | null;
      province?: string | null;
      country_code?: string | null;
      national_id?: string | null;
      ssn?: string | null;
      language?: string | null;
      email?: string | null;
      phone?: string | null;
      marketing_consented_at?: string | null;
    }>;
    additional_deliveries?: Array<{
      delivery_id: number | null;
      type: string | null; //"summerwheel_delivery",
      handover_method: string | null;
      status: string | null;
      completed_at: string | null;
      updated_in_vista_at: string | null;
      photo_document_ref: string | null;
      identification_type: string | null;
      identification_number: string | null;
      signed_document_ref: string | null;
      partner?: {
        capabilities: Array<String | null> | null;
        service_partner_po_number: string | null;
        parma_code: string | null;
        location_code: string | null;
        partner_id: string | null;
        name: string | null;
        phone: string | null;
        address_line_1: string | null;
        zip_code: string | null;
        city: string | null;
        country_code: string | null;
      } | null;
      partners?: Array<{
        capabilities: Array<String | null> | null;
        service_partner_po_number: string | null;
        parma_code: string | null;
        location_code: string | null;
        partner_id: string | null;
        name: string | null;
        phone: string | null;
        address_line_1: string | null;
        zip_code: string | null;
        city: string | null;
        country_code: string | null;
      }> | null;
    }>;
    company_data?: {
      roles?: Array<string>;
      cdb_id?: string | null;
      salesforce_id?: string | null;
      name?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      phone?: string | null;
      address_line_1?: string | null;
      address_line_2?: string | null;
      country_code?: string | null;
      zip_code?: string | null;
      city?: string | null;
      vat_number?: string | null;
      organization_number?: string | null;
    };
    fsp_data?: {
      roles?: Array<string>;
      salesforce_id?: string | null;
      cdb_id?: string | null;
      name?: string | null; // THIS!
      organization_number?: string | null;
    };
    sales_contract?: {
      status?: 'open' | 'signing_requested' | 'signed' | '' | null;
      additional_contract_signers?: Array<{
        name?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
      }> | null;
      document_name?: string | null;
    };
    registration?: {
      licence_plate?: string | null;
      vehicle_serial_number?: string | null;
      registration_date?: string | null;
      certificate_number?: string | null;
      dk_registration_information?: {
        importer_registration_code?: string | null;
        registered_tax_value?: string | null;
      };
      registered_owners?: Array<{
        name?: string | null;
        id_type?: 'organisationNumber' | 'socialSecurityNumber' | null; // 'social_security_number' | 'organisation_number' | null; // social_security_number | organisation_number for B2B
        identification_number?: string | null;
        date_of_birth?: string | null;
        gender?: string | null;
        email?: string | null;
        phone_number?: string | null;
        drivers_license_number?: string | null;
        drivers_license_expiry_date?: string | null;
        customer_number?: string | null;
        residential_address?: {
          address_line1?: string | null;
          address_line2?: string | null;
          address_line3?: string | null;
          suburb?: string | null;
          state_code?: string | null;
          country?: string | null;
          postal_code?: string | null;
        } | null;
        mailing_address?: {
          address_line1?: string | null;
          address_line2?: string | null;
          address_line3?: string | null;
          suburb?: string | null;
          state_code?: string | null;
          country?: string | null;
          postal_code?: string | null;
        } | null;
      }>;
      possessors?: Array<{
        ssn?: string | null;
        name?: string | null;
      }>;
      de_registration_information?: {
        first_customer_contact_date?: string | null;
        released_for_registration?: string | null;
        registration_started_date?: string | null;
        speed_registration_status?: keyof typeof deRegistrationInformationStatus | null;
        speed_registration_status_group?: string | null;
        registration_updated_at?: string | null;
      };
      registration_details?: {
        // Use this in registration popup
        broker_name?: string | null;
        broker_email?: string | null;
        broker_phone_number?: string | null;
        documents?: Array<{
          documentName?: string | null;
        }>;
        contract_reference_number?: string | null;
        insurance_type?: string | null;
        garage_address?: {
          type_of_use?: string | null;
          address_line1?: string | null;
          address_line2?: string | null;
          address_line3?: string | null;
          suburb?: string | null;
          state_code?: string | null;
          country?: string | null;
          postal_code?: string | null;
        } | null;
        concession_requested?: boolean | null;
      };
      requested_insurance?: {
        insurance_type?: 'dealer_arranged' | 'personal' | null;
        insurance_option?: string | null;
        insurance_provider?: string | null;
        insurance_policy_holder?: string | null;
        other_information?: string | null;
      };
    };
    handover_booking?: {
      earliest_possible_handover_date?: string | null;
      customer_required_earliest_handover_date?: string | null;
      planned_delivery_date?: string | null;
      preliminary_delivery_date?: string | null;
      handover_time_status?:
        | 'estimated'
        | 'suggested'
        | 'accepted'
        | 'rejected'
        | 'cancelled'
        | 'removed'
        | null;
      handover_time_status_at?: string | null;
      customer_rejection_notes?: string | null;
      suggested_handover_times_at?: string | null;
      suggested_handover_times?: Array<{
        start?: string | null;
        end?: string | null;
        timezone?: string | null;
      }>;
      accepted_handover_time?: {
        start?: string | null;
        end?: string | null;
      };
      insurance?: 'none' | 'polestar' | 'personal' | '';
      polestar_insurance_confirmed?: boolean; // Use this to determine if we can show registration popup under preparation
      needs_winter_wheel_mounting?: boolean;
      handover_method?:
        | 'collect'
        | 'collect_essential'
        | 'home_delivery'
        | 'home_delivery_essential'
        | 'paid_home_delivery'
        | 'delivery_at_car_handover';
      handover_location?: {
        name?: string | null;
        address_line_1?: string | null;
        zip_code?: string | null;
        city?: string | null;
        country_code?: string | null;
        latitude?: string | null;
        longitude?: string | null;
        code?: string | null;
      };
      authorised_to_pick_up_the_car?: {
        name?: string | null;
        SSN?: string | null;
      };
      handover_notes?: string | null;
      fleet_notes?: string | null;
    };
    handover?: {
      completed_at?: string | null;
      odometer?: string | null;
      photo_document_ref?: string | null;
      identification_number?: string | null;
      signed_document_ref?: string | null;
    };
    partner?: Array<Partner | null>;
    financial_information?: {
      currency?: string | null;
      total_price?: number;
      total_vat?: number;
      total_tax?: number;
      grand_total_car_extras_delivery_basic_price_incl_vat?: number;
      discount_total_price?: number;
      discount_total_tax?: number;
      car_total_price?: number;
      car_total_vat?: number;
      car_total_tax?: number;
      extras_total_price?: number;
      extras_total_vat?: number;
      delivery_total_price?: number;
      delivery_total_vat?: number;
      charges?: Array<{
        type?: string | null;
        amount?: number;
        vat_rate?: number;
      }>;
      taxes?: Array<{
        type?: string | null;
        amount?: number;
        vat_rate?: number;
      }> | null;
      deposit_conversion_requested?: false;
      financial_product?:
        | 'Cash'
        | 'Loan'
        | 'Loanballoon'
        | 'LoanBalloonGFV'
        | 'Leasing'
        | 'Subscription'
        | 'Personal Loan'
        | 'Personal Leasing'
        | 'FinancialLease';
      billing_address?: {
        ba_address_line_1?: string | null;
        ba_address_line_2?: string | null;
        ba_zip_code?: string | null;
        ba_city?: string | null;
        ba_district?: string | null;
        ba_province?: string | null;
        ba_country_code?: string | null;
      };
      cash_payment?: {
        cash_payment_status?: string | null;
        bank_account_holder_name?: string | null;
        bank_account_verification?: string | null;
        transactional_id?: string | null;
        payments?: Array<{
          payment_type?: string | null;
          payment_provider?: string | null;
          payment_reference?: string | null;
          payment_amount?: number;
          payment_date?: string | null;
          payment_currency?: string | null;
        }>;
      };
      other_payment?: {
        monthly_payment_amount?: number;
        monthly_payment_currency?: string | null;
        financial_service_provider?: string | null;
        annual_mileage?: number | null;
        terms?: number | null;
        upfront_payment?: number;
        other_payment_status?: 'confirmed' | 'signed' | 'other'; //TODO Is other a thing?
        financing_application_id?: string | null; // NEW
        loan_amount?: number | null;
      };
      personal_financing?: {
        currency?: string | null;
        financed_amount?: number;
        loan_provider_name?: string | null;
        status?: 'pending' | 'approval_requested' | 'approved' | 'rejected';
        documents?: Array<{
          document_name?: string | null;
        }>;
      };
      final_payment?: {
        buyer?: {
          status?: string | null;
          paid_final_payment_to_external_partner?: boolean | null;
          amount?: number;
          amount_label?: string | null;
          amount_paid?: number;
          amount_paid_by_buyer?: number;
          writeoff_amount?: number;
          currency?: string | null;
          payment_advice_details?: {
            amount_without_delivery_charge?: {
              label?: string | null;
              value?: number;
            };
            delivery_charge?: {
              label?: string | null;
              value?: number;
            };
            vat?: {
              label?: string | null;
              value?: number;
            };
          };
          payments?: Array<{
            amount?: number;
            date?: string | null;
            account_number?: string | null;
          }>;
        };
        fsp?: {
          amount?: number;
          discount?: number;
          discount_amount?: number;
          currency?: string | null;
        };
      };
    };
    cardata_for_extras?: {
      model_code?: string | null;
      model_year?: string | null;
      vin?: string | null;
      vin_created_at?: string | null;
    };
    orderlines?: Array<OrderLine>;
    event_type?: 'order_created' | 'order_updated' | 'order_cancelled';
    event_time?: string | null;
    trigger?: string | null;
    source?: string | null;
    salesforce_status?:
      | 'CustomerCheckApproved'
      | 'PartialPaymentPending'
      | 'PaymentComplete'
      | 'OrderCancelled'
      | 'ExtrasAdded'
      | 'OrderlineModified';
    remarketed_car?: {
      features?: Array<string> | null;
    } | null;
  } | null;
}

export const fullOrderPropType = {
  data: shape({
    order_no: string,
    external_order_no: string,
    market: string,
    iso_language_code: string,
    order_type: oneOf<String>(['pre-order', 'order', 'extras-order', 'fleet', 'remarketed']),
    sales_type: oneOf<String>(['B2B', 'B2C']),
    order_state: oneOf<String>([
      'pending',
      'confirmed',
      'delivery_planning',
      'delivered',
      'cancelled',
      'converted',
      'pending_return',
      'returned',
      'partially_delivered'
    ]),
    lock_state: oneOf<String>(['unlocked', 'locked', 'manually_unlocked']),
    logistic_flow: string,
    delivered_together_with: string,
    preorder_id: string,
    related_order_id: string,
    related_extra_orders: arrayOf(string),
    purchasing_order_number: string,
    importer: string,
    ordering_dealer: string,
    destination: string,
    selling_dealer: string,
    delivering_dealer: string,
    order_url: string,
    terms_and_conditions_url: string,
    order_cancellation_reason: string,
    order_cancellation_note: string,
    order_created_at: string,
    order_modified_at: string,
    roles: arrayOf(
      shape({
        type: string,
        cdb_id: string,
        salesforce_id: string
      })
    ),
    ev_subsidy_information: shape({
      ev_subsidy_status: string,
      ev_subsidy_allocation_date: string,
      ev_subsidy_amount: string,
      ev_subsidy_confirmation_date: string,
      ev_subsidy_cancellation_date: string
    }),
    fleet: shape({
      operator_id: string,
      case_id: string,
      reference_number: string,
      external_order_date: string,
      order_url: string
    }),
    customer_data: shape({
      cdb_id: string,
      cdb_market: string,
      polestar_id: string,
      salesforce_id: string,
      consumer_type: string,
      first_name: string,
      last_name: string,
      date_of_birth: string,
      address_line_1: string,
      address_line_2: string,
      zip_code: string,
      city: string,
      district: string,
      province: string,
      country_code: string,
      national_id: string,
      language: string,
      email: string,
      phone: string,
      marketing_consented_at: string,
      created_at: string,
      modified_at: string,
      b2b_consumer: shape({ registered_company_name: string, company_vat_num: string })
    }),
    person_data: arrayOf(
      shape({
        roles: arrayOf(string),
        cdb_id: string,
        cdb_market: string,
        polestar_id: string,
        salesforce_id: string,
        first_name: string,
        last_name: string,
        date_of_birth: string,
        address_line_1: string,
        address_line_2: string,
        zip_code: string,
        city: string,
        district: string,
        province: string,
        country_code: string,
        national_id: string,
        ssn: string,
        language: string,
        email: string,
        phone: string,
        marketing_consented_at: string
      })
    ),
    company_data: shape({
      roles: arrayOf(string),
      cdb_id: string,
      cdb_market: string,
      polestar_id: string,
      salesforce_id: string,
      first_name: string,
      last_name: string,
      date_of_birth: string,
      address_line_1: string,
      address_line_2: string,
      zip_code: string,
      city: string,
      vat_number: string,
      organization_number: string
    }),
    fsp_data: shape({
      roles: arrayOf(string),
      salesforce_id: string,
      cdb_id: string,
      name: string,
      organization_number: string
    }),
    sales_contract: shape({
      status: oneOf<String | null>(['open', 'signing_requested', 'signed', '', null]),
      additional_contract_signers: arrayOf(
        shape({
          name: string,
          first_name: string,
          last_name: string,
          email: string
        })
      ),
      document_name: string
    }),
    registration: shape({
      licence_plate: string,
      vehicle_serial_number: string,
      registered_owners: arrayOf(
        shape({
          name: string,
          id_type: oneOf([
            'organizationNumber',
            'socialSecurityNumber',
            'social_security_number',
            'organisation_number',
            null
          ]),
          identification_number: string
        })
      ),
      registration_details: shape({
        broker_name: string,
        broker_email: string,
        broker_phone_number: string,
        documents: arrayOf(
          shape({
            documentName: string
          })
        )
      })
    }),
    handover_booking: shape({
      earliest_possible_handover_date: string,
      customer_required_earliest_handover_date: string,
      planned_delivery_date: string,
      preliminary_delivery_date: string,
      suggested_handover_times_at: string,
      suggested_handover_times: arrayOf(
        shape({
          start: string,
          end: string,
          timezone: string
        })
      ),
      accepted_handover_time: shape({
        start: string,
        end: string
      }),
      insurance: oneOf(['none', 'polestar', 'personal', 'Polestar', '']),
      polestar_insurance_confirmed: bool,
      needs_winter_wheel_mounting: bool,
      handover_method: oneOf([
        'collect',
        'home_delivery',
        'paid_home_delivery',
        'delivery_at_car_handover'
      ]),
      handover_location: shape({
        name: string,
        address_line_1: string,
        zip_code: string,
        city: string,
        country_code: string,
        latitude: string,
        longitude: string,
        code: string
      }),
      authorised_to_pick_up_the_car: shape({
        name: string,
        SSN: string
      }),
      handover_notes: string,
      fleet_notes: string
    }),
    handover: shape({
      completed_at: string,
      odometer: string,
      photo_document_ref: string,
      identification_number: string,
      signed_document_ref: string
    }),
    partner: arrayOf(
      shape({
        capabilities: arrayOf(string),
        parma_code: string,
        location_code: string,
        partner_id: string
      })
    ),
    financial_information: shape({
      currency: string,
      total_price: number,
      total_vat: number,
      total_tax: number,
      discount_total_price: number,
      discount_total_tax: number,
      car_total_price: number,
      car_total_vat: number,
      car_total_tax: number,
      extras_total_price: number,
      extras_total_vat: number,
      delivery_total_price: number,
      delivery_total_vat: number,
      charges: arrayOf(
        shape({
          type: string,
          amount: number,
          vat_rate: number
        })
      ),
      taxes: arrayOf(
        shape({
          type: string,
          amount: number,
          vat_rate: number
        })
      ),
      deposit_conversion_requested: bool,
      financial_product: oneOf([
        'Cash',
        'Loan',
        'Loanballoon',
        'LoanBalloonGFV',
        'Leasing',
        'Subscription',
        'Personal Loan',
        'Personal Leasing'
      ]),
      billing_address: shape({
        ba_address_line_1: string,
        ba_address_line_2: string,
        ba_zip_code: string,
        ba_city: string,
        ba_district: string,
        ba_province: string,
        ba_country_code: string
      }),
      cash_payment: shape({
        cash_payment_status: string,
        bank_account_holder_name: string,
        bank_account_verification: string,
        transactional_id: string,
        payments: arrayOf(
          shape({
            payment_type: string,
            payment_provider: string,
            payment_reference: string,
            payment_amount: number,
            payment_date: string,
            payment_currency: string
          })
        )
      }),
      other_payment: shape({
        monthly_payment_amount: number,
        monthly_payment_currency: string,
        financial_service_provider: string,
        annual_mileage: number,
        terms: number,
        upfront_payment: number,
        other_payment_status: oneOf(['confirmed', 'signed', 'other']),
        financing_application_id: string,
        loan_amount: number
      }),
      personal_financing: shape({
        currency: string,
        financed_amount: number,
        loan_provider_name: string,
        status: oneOf(['pending', 'approval_requested', 'approved', 'rejected']),
        documents: arrayOf(
          shape({
            document_name: string
          })
        )
      }),
      final_payment: shape({
        buyer: shape({
          status: string,
          amount: number,
          amount_label: string,
          amount_paid: number,
          writeoff_amount: number,
          currency: string,
          payment_advice_details: shape({
            amount_without_delivery_charge: shape({
              label: string,
              value: number
            }),
            delivery_charge: shape({
              label: string,
              value: number
            }),
            vat: shape({
              label: string,
              value: number
            })
          }),
          payments: arrayOf(
            shape({
              amount: number,
              date: string,
              account_number: string
            })
          )
        }),
        fsp: shape({
          amount: number,
          discount: number,
          discount_amount: number,
          currency: string
        })
      })
    }),
    cardata_for_extras: shape({
      model_code: string,
      model_year: string,
      vin: string,
      vin_created_at: string
    }),
    orderlines: arrayOf(orderlinePropType), //orderline
    event_type: oneOf(['order_created', 'order_updated', 'order_cancelled']),
    event_time: string,
    trigger: string,
    source: string,
    salesforce_status: oneOf([
      'CustomerCheckApproved',
      'PartialPaymentPending',
      'PaymentComplete',
      'OrderCancelled',
      'ExtrasAdded',
      'OrderlineModified'
    ]),
    remarketed_car: shape({
      features: arrayOf(string)
    })
  })
};

export const getOrderContractMock: (dataIn?: Partial<OrderContract>) => OrderContract = (
  dataIn: Partial<OrderContract> = {}
) => ({
  data: {
    order_no: '11002669',
    external_order_no: null,
    market: 'SE',
    iso_language_code: 'sv-SE',
    order_type: 'order' as const,
    sales_type: 'B2B' as const,
    order_state: 'confirmed' as const,
    lock_state: 'unlocked' as const,
    preorder_id: null,
    selling_dealer: null,
    delivering_dealer: null,
    order_url: 'https://staging-oms.polestar.com/nova/resources/orders/3943',
    terms_and_conditions_url: null,
    order_cancellation_reason: null,
    order_cancellation_note: null,
    order_created_at: '2020-04-15T13:34:33Z',
    order_modified_at: '2020-04-29T12:05:26Z',
    roles: [
      {
        type: 'orderer',
        cdb_id: '170107845',
        salesforce_id: '0018E00001LamfSQAR'
      }
    ],
    fleet: {
      operator_id: null,
      case_id: null,
      reference_number: null,
      external_order_date: null,
      order_url: null,
      parent_case_id: ''
    },
    sales_contract: {
      status: 'signing_requested' as const,
      additional_contract_signers: [
        {
          name: 'Test Test',
          email: 'myorder2b2bse+333@gmail.com',
          last_name: 'Test',
          first_name: 'Test'
        },
        {
          name: 'Jacob Agardh',
          email: 'jacobagardh+signee5@gmail.com',
          last_name: 'Agardh',
          first_name: 'Jacob'
        }
      ],
      document_name: ''
    },
    registration: {
      licence_plate: '',
      registered_owners: [],
      registration_date: ''
    },
    handover_booking: {
      earliest_possible_handover_date: '2020-07-21',
      planned_delivery_date: '2020-07-01',
      preliminary_delivery_date: null,
      suggested_handover_times_at: null,
      suggested_handover_times: [],
      accepted_handover_time: {},
      insurance: '',
      needs_winter_wheel_mounting: false,
      handover_method: 'collect',
      handover_location: {
        name: 'BRA BIL I GOTEBORG AB',
        address_line_1: 'IMPORTGATAN 3',
        zip_code: '422 46',
        city: 'HISINGS  BACKA',
        country_code: 'SE',
        latitude: '57.7460151',
        longitude: '11.9920325'
      },
      handover_notes: 'test handover note',
      fleet_notes: ''
    },
    customer_data: {
      cdb_id: '170107845',
      cdb_market: 'PO',
      polestar_id: '56f86e2c-1326-4cf8-922a-8515b8db7d69',
      salesforce_id: '0018E00001LamfSQAR',
      consumer_type: 'B2B',
      first_name: 'First Name xtlfasfm',
      last_name: 'Last Name xtlfasfm',
      date_of_birth: null,
      address_line_1: 'Skolgatan 38a, G\u00f6teborg, Sverige',
      address_line_2: null,
      zip_code: '41302',
      city: 'G\u00f6teborg',
      district: null,
      province: null,
      country_code: 'SE',
      national_id: null,
      language: 'sv',
      email: 'myorder2b2bse+220546@gmail.com',
      phone: '+47123123123',
      marketing_consented_at: null,
      created_at: '2020-04-15T13:33:59Z',
      modified_at: '2020-04-15T16:06:54Z',
      b2b_consumer: {
        registered_company_name: 'Polestar',
        company_vat_num: 'SE454353445301'
      }
    },
    person_data: [
      {
        roles: ['orderer', 'driver'],
        cdb_id: '170107845',
        cdb_market: 'PO',
        polestar_id: '56f86e2c-1326-4cf8-922a-8515b8db7d69',
        salesforce_id: '0018E00001LamfSQAR',
        first_name: 'First Name xtlfasfm',
        last_name: 'Last Name xtlfasfm',
        date_of_birth: null,
        address_line_1: 'Skolgatan 38a, G\u00f6teborg, Sverige',
        address_line_2: null,
        zip_code: '41302',
        city: 'G\u00f6teborg',
        district: null,
        province: null,
        country_code: 'SE',
        national_id: null,
        language: 'sv',
        email: 'myorder2b2bse+220546@gmail.com',
        phone: '+47123123123',
        marketing_consented_at: null
      }
    ],
    company_data: {
      roles: ['owner', 'buyer'],
      salesforce_id: null,
      cdb_id: null,
      name: 'Polestar',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      address_line_1: '',
      address_line_2: '',
      country_code: 'SE',
      zip_code: '',
      city: '',
      vat_number: 'SE454353445301',
      organization_number: '5592256258'
    },
    handover: {
      completed_at: null,
      odometer: null,
      photo_document_ref: null,
      identification_number: null,
      signed_document_ref: null
    },
    partner: [
      {
        capabilities: ['pdi_pds', 'handover'],
        parma_code: '',
        location_code: 'SEGOT0272',
        partner_id: ''
      }
    ],
    fsp_data: {},
    financial_information: {
      currency: 'SEK',
      total_price: 728750,
      total_vat: 145750,
      total_tax: 145600,
      discount_total_price: 0,
      discount_total_tax: 0,
      car_total_price: 692000,
      car_total_vat: 138400,
      car_total_tax: 145600,
      extras_total_price: 36750,
      extras_total_vat: 7350,
      delivery_total_price: 0,
      delivery_total_vat: 0,
      charges: undefined,
      taxes: null,
      deposit_conversion_requested: false,
      financial_product: 'Cash',
      billing_address: {
        ba_address_line_1: 'Skolgatan 38a, G\u00f6teborg, Sverige',
        ba_address_line_2: null,
        ba_zip_code: '41302',
        ba_city: 'G\u00f6teborg',
        ba_district: null,
        ba_province: null,
        ba_country_code: 'SE'
      },
      cash_payment: {
        cash_payment_status: 'partially_paid',
        bank_account_holder_name: null,
        bank_account_verification: null,
        transactional_id: 'ad274785-3d46-4d11-af63-477ee2ecfbdf',
        payments: [
          {
            payment_type: 'down_payment',
            payment_provider: 'Stripe',
            payment_amount: 10000,
            payment_reference: 'ad274785-3d46-4d11-af63-477ee2ecfbdf',
            payment_date: '2020-04-15',
            payment_currency: 'SEK'
          }
        ]
      },
      other_payment: {
        monthly_payment_amount: 0,
        monthly_payment_currency: 'SEK',
        financial_service_provider: null,
        annual_mileage: null,
        terms: null,
        upfront_payment: 0,
        other_payment_status: 'other',
        financing_application_id: null,
        loan_amount: null
      },
      final_payment: {
        buyer: {
          status: 'partially_paid',
          amount: 0,
          amount_label: null,
          amount_paid: 0,
          writeoff_amount: 0,
          currency: 'SEK',
          payment_advice_details: {},
          payments: []
        },
        fsp: {
          amount: 728750,
          discount: 0,
          discount_amount: 0,
          currency: 'SEK'
        }
      }
    },
    cardata_for_extras: {
      model_code: '534',
      model_year: '2021',
      vin: 'LPSWSEKFEML135065',
      vin_created_at: null
    },
    orderlines: [
      {
        orderline_id: 7490,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'cancelled',
        unit_price: 692000,
        unit_vat: 138400,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'ConsumerCar',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        car_configuration: {
          configuration_id: '507fae95-00a2-4954-9dc0-4febde35c6d4',
          model: 'Polestar 2',
          upholstery: 'Charcoal WeaveTech with Black Ash deco',
          colour: 'Magnesium',
          wheels_rims: '20\u201d svart diamond cut 4-V ekrad l\u00e4ttmetallf\u00e4lg',
          performance: null,
          package_codes: [TOW_BAR_PART_NR],
          exterior_details_brightworks: null,
          pno_34: '534EDPI0E11972900RF8000      00000001147900436',
          exterior_image_url: 'url',
          specifications: [
            {
              label: 'Elektriska motorer',
              value: '2 elektriska motorer (fram- och bakaxel)'
            },
            {
              label: 'Effekt',
              value: '300 kW / 408 hk'
            },
            {
              label: 'Vridmoment',
              value: '660 Nm'
            },
            {
              label: 'Batterier',
              value: '78 kWh i 27 moduler'
            },
            {
              label: '0 till 100 kilometer i timmen',
              value: '4.7 sek'
            },
            {
              label: 'Elektriska motorer',
              value: '2 elektriska motorer (fram- och bakaxel)'
            },
            {
              label: 'Effekt',
              value: '300 kW / 408 hk'
            },
            {
              label: 'Vridmoment',
              value: '660 Nm'
            },
            {
              label: 'Batterier',
              value: '78 kWh i 27 moduler'
            },
            {
              label: '0 till 100 kilometer i timmen',
              value: '4.7 sek'
            }
          ],
          dimensions: [
            {
              label: 'L\u00e4ngd',
              value: '4606 mm'
            },
            {
              label: 'H\u00f6jd',
              value: '1479 mm'
            },
            {
              label: 'Bredd inkl backspeglar',
              value: '1985 mm'
            },
            {
              label: 'Hjulbas',
              value: '2735 mm'
            },
            {
              label: 'Markfrig\u00e5ng',
              value: '151 mm (146 mm med Performance-paketet)'
            },
            {
              label: 'L\u00e4ngd',
              value: '4606 mm'
            },
            {
              label: 'H\u00f6jd',
              value: '1479 mm'
            },
            {
              label: 'Bredd inkl backspeglar',
              value: '1985 mm'
            },
            {
              label: 'Hjulbas',
              value: '2735 mm'
            },
            {
              label: 'Markfrig\u00e5ng',
              value: '151 mm (146 mm med Performance-paketet)'
            }
          ]
        },
        car_delivery_preferencies: {
          delivery_option: null,
          has_insurance: null,
          personal_id_number: null
        },
        car_factory_order: {
          meta_order_number: '12345',
          factory_order_number: null,
          vin: 'LPSWSEKFEML135065',
          chassis_number: null,
          actual_vista_status_point: null,
          car_factory_order_statuses: [],
          factory_code: null,
          last_day_for_specification_change_date: null,
          planned_factory_complete_day: null,
          accepted_planned_build_date: null,
          accepted_planned_delivery_date: '2020-07-01',
          current_planned_build_date: null,
          current_planned_delivery_date: '2020-07-01',
          current_dealer_delivery_date: '2020-07-01',
          pds_date: null
        }
      },
      {
        orderline_id: 7491,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 1000,
        unit_vat: 0,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: 'A00492',
        extra_orderline_type: 'Package',
        extra_product_type: 'Mat',
        name: 'Front and rear passenger floor mats',
        quantity: 1,
        package_id: null,
        package_name: null,
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7492,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 1000,
        unit_vat: 200,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: '32261146',
        extra_orderline_type: 'Product',
        extra_product_type: 'Mat',
        name: 'Front and rear passenger floor mats',
        quantity: 1,
        package_id: 'A00492',
        package_name: 'Front and rear passenger floor mats',
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7493,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 30000,
        unit_vat: 0,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: 'A01164',
        extra_orderline_type: 'Package',
        extra_product_type: 'Winter Wheels',
        name: '20\u201d nordic friction',
        quantity: 1,
        package_id: null,
        package_name: null,
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7494,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 30000,
        unit_vat: 6000,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: '32147833',
        extra_orderline_type: 'Product',
        extra_product_type: 'Winter Wheels',
        name: 'Complete Winter, 20", Friction, Nordic',
        quantity: 1,
        package_id: 'A01164',
        package_name: '20\u201d nordic friction',
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7495,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 2750,
        unit_vat: 0,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: 'A00379',
        extra_orderline_type: 'Package',
        extra_product_type: 'Child seat',
        name: 'Booster seat',
        quantity: 1,
        package_id: null,
        package_name: null,
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7496,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 1125,
        unit_vat: 225,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: '31470488',
        extra_orderline_type: 'Product',
        extra_product_type: 'Child seat',
        name: 'Child seat, Booster cushion',
        quantity: 1,
        package_id: 'A00379',
        package_name: 'Booster seat',
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7497,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 1625,
        unit_vat: 325,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: '31470519',
        extra_orderline_type: 'Product',
        extra_product_type: 'Child seat',
        name: 'Backrest',
        quantity: 1,
        package_id: 'A00379',
        package_name: 'Booster seat',
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7498,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 3000,
        unit_vat: 0,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: 'WS00001',
        extra_orderline_type: 'Package',
        extra_product_type: 'Tire Hotel',
        name: 'Wheel storage',
        quantity: 1,
        package_id: null,
        package_name: null,
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7499,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 3000,
        unit_vat: 600,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: 'PWW1WC',
        extra_orderline_type: 'Product',
        extra_product_type: 'Tire Hotel',
        name: 'Wheel change/one year of storage',
        quantity: 1,
        package_id: 'WS00001',
        package_name: 'Wheel storage',
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      }
    ],
    remarketed_car: {
      features: null
    },
    ...dataIn.data
  }
});
export const mockOrderResponse: OrderContract = {
  data: {
    order_no: '11002669',
    external_order_no: null,
    market: 'SE',
    iso_language_code: 'sv-SE',
    order_type: 'order' as const,
    sales_type: 'B2B' as const,
    order_state: 'confirmed' as const,
    lock_state: 'unlocked' as const,
    logistic_flow: null,
    preorder_id: null,
    selling_dealer: null,
    delivering_dealer: null,
    order_url: 'https://staging-oms.polestar.com/nova/resources/orders/3943',
    terms_and_conditions_url: null,
    order_cancellation_reason: null,
    order_cancellation_note: null,
    order_created_at: '2020-04-15T13:34:33Z',
    order_modified_at: '2020-04-29T12:05:26Z',
    roles: [
      {
        type: 'orderer',
        cdb_id: '170107845',
        salesforce_id: '0018E00001LamfSQAR'
      }
    ],
    fleet: {
      operator_id: null,
      case_id: null,
      reference_number: null,
      external_order_date: null,
      order_url: null,
      parent_case_id: ''
    },
    sales_contract: {
      status: 'signing_requested' as const,
      additional_contract_signers: [
        {
          name: 'Test Test',
          email: 'myorder2b2bse+333@gmail.com',
          last_name: 'Test',
          first_name: 'Test'
        },
        {
          name: 'Jacob Agardh',
          email: 'jacobagardh+signee5@gmail.com',
          last_name: 'Agardh',
          first_name: 'Jacob'
        }
      ],
      document_name: ''
    },
    registration: {
      licence_plate: '',
      registered_owners: [],
      registration_date: ''
    },
    handover_booking: {
      earliest_possible_handover_date: '2020-07-21',
      planned_delivery_date: '2020-07-01',
      preliminary_delivery_date: null,
      suggested_handover_times_at: null,
      suggested_handover_times: [],
      accepted_handover_time: {},
      insurance: '',
      needs_winter_wheel_mounting: false,
      handover_method: 'collect',
      handover_location: {
        name: 'BRA BIL I GOTEBORG AB',
        address_line_1: 'IMPORTGATAN 3',
        zip_code: '422 46',
        city: 'HISINGS  BACKA',
        country_code: 'SE',
        latitude: '57.7460151',
        longitude: '11.9920325',
        code: 'SEGOT0272'
      },
      handover_notes: 'test handover note',
      fleet_notes: ''
    },
    customer_data: {
      cdb_id: '170107845',
      cdb_market: 'PO',
      polestar_id: '56f86e2c-1326-4cf8-922a-8515b8db7d69',
      salesforce_id: '0018E00001LamfSQAR',
      consumer_type: 'B2B',
      first_name: 'First Name xtlfasfm',
      last_name: 'Last Name xtlfasfm',
      date_of_birth: null,
      address_line_1: 'Skolgatan 38a, G\u00f6teborg, Sverige',
      address_line_2: null,
      zip_code: '41302',
      city: 'G\u00f6teborg',
      district: null,
      province: null,
      country_code: 'SE',
      national_id: null,
      language: 'sv',
      email: 'myorder2b2bse+220546@gmail.com',
      phone: '+47123123123',
      marketing_consented_at: null,
      created_at: '2020-04-15T13:33:59Z',
      modified_at: '2020-04-15T16:06:54Z',
      b2b_consumer: {
        registered_company_name: 'Polestar',
        company_vat_num: 'SE454353445301'
      }
    },
    person_data: [
      {
        roles: ['orderer', 'driver'],
        cdb_id: '170107845',
        cdb_market: 'PO',
        polestar_id: '56f86e2c-1326-4cf8-922a-8515b8db7d69',
        salesforce_id: '0018E00001LamfSQAR',
        first_name: 'First Name xtlfasfm',
        last_name: 'Last Name xtlfasfm',
        date_of_birth: null,
        address_line_1: 'Skolgatan 38a, G\u00f6teborg, Sverige',
        address_line_2: null,
        zip_code: '41302',
        city: 'G\u00f6teborg',
        district: null,
        province: null,
        country_code: 'SE',
        national_id: null,
        language: 'sv',
        email: 'myorder2b2bse+220546@gmail.com',
        phone: '+47123123123',
        marketing_consented_at: null
      }
    ],
    company_data: {
      roles: ['owner', 'buyer'],
      salesforce_id: null,
      cdb_id: null,
      name: 'Polestar',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      address_line_1: '',
      address_line_2: '',
      country_code: 'SE',
      zip_code: '',
      city: '',
      vat_number: 'SE454353445301',
      organization_number: '5592256258'
    },
    handover: {
      completed_at: '2020-04-15T13:33:59Z',
      odometer: null,
      photo_document_ref: null,
      identification_number: null,
      signed_document_ref: null
    },
    partner: [
      {
        capabilities: ['pdi_pds', 'handover', 'transport_partner'],
        parma_code: '',
        location_code: 'SEGOT0272',
        partner_id: ''
      }
    ],
    fsp_data: {},
    financial_information: {
      currency: 'SEK',
      total_price: 728750,
      total_vat: 145750,
      total_tax: 145600,
      discount_total_price: 0,
      discount_total_tax: 0,
      car_total_price: 692000,
      car_total_vat: 138400,
      car_total_tax: 145600,
      extras_total_price: 36750,
      extras_total_vat: 7350,
      delivery_total_price: 0,
      delivery_total_vat: 0,
      charges: undefined,
      taxes: null,
      deposit_conversion_requested: false,
      financial_product: 'Cash',
      billing_address: {
        ba_address_line_1: 'Skolgatan 38a, G\u00f6teborg, Sverige',
        ba_address_line_2: null,
        ba_zip_code: '41302',
        ba_city: 'G\u00f6teborg',
        ba_district: null,
        ba_province: null,
        ba_country_code: 'SE'
      },
      cash_payment: {
        cash_payment_status: 'partially_paid',
        bank_account_holder_name: null,
        bank_account_verification: null,
        transactional_id: 'ad274785-3d46-4d11-af63-477ee2ecfbdf',
        payments: [
          {
            payment_type: 'down_payment',
            payment_provider: 'Stripe',
            payment_amount: 10000,
            payment_reference: 'ad274785-3d46-4d11-af63-477ee2ecfbdf',
            payment_date: '2020-04-15',
            payment_currency: 'SEK'
          }
        ]
      },
      other_payment: {
        monthly_payment_amount: 0,
        monthly_payment_currency: 'SEK',
        financial_service_provider: null,
        annual_mileage: null,
        terms: null,
        upfront_payment: 0,
        other_payment_status: 'other',
        financing_application_id: null,
        loan_amount: null
      },
      final_payment: {
        buyer: {
          status: 'partially_paid',
          amount: 0,
          amount_label: null,
          amount_paid: 0,
          writeoff_amount: 0,
          currency: 'SEK',
          payment_advice_details: {},
          payments: []
        },
        fsp: {
          amount: 728750,
          discount: 0,
          discount_amount: 0,
          currency: 'SEK'
        }
      }
    },
    cardata_for_extras: {
      model_code: '534',
      model_year: '2021',
      vin: 'LPSWSEKFEML135065',
      vin_created_at: null
    },
    orderlines: [
      {
        orderline_id: 7490,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'delivered',
        unit_price: 692000,
        unit_vat: 138400,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'ConsumerCar',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        car_configuration: {
          configuration_id: '507fae95-00a2-4954-9dc0-4febde35c6d4',
          model: 'Polestar 2',
          upholstery: 'Charcoal WeaveTech with Black Ash deco',
          colour: 'Magnesium',
          wheels_rims: '20\u201d svart diamond cut 4-V ekrad l\u00e4ttmetallf\u00e4lg',
          performance: null,
          package_codes: [TOW_BAR_PART_NR],
          exterior_details_brightworks: null,
          pno_34: '534EDPI0E11972900RF8000      00000001147900436',
          exterior_image_url: 'url',
          specifications: [
            {
              label: 'Elektriska motorer',
              value: '2 elektriska motorer (fram- och bakaxel)'
            },
            {
              label: 'Effekt',
              value: '300 kW / 408 hk'
            },
            {
              label: 'Vridmoment',
              value: '660 Nm'
            },
            {
              label: 'Batterier',
              value: '78 kWh i 27 moduler'
            },
            {
              label: '0 till 100 kilometer i timmen',
              value: '4.7 sek'
            },
            {
              label: 'Elektriska motorer',
              value: '2 elektriska motorer (fram- och bakaxel)'
            },
            {
              label: 'Effekt',
              value: '300 kW / 408 hk'
            },
            {
              label: 'Vridmoment',
              value: '660 Nm'
            },
            {
              label: 'Batterier',
              value: '78 kWh i 27 moduler'
            },
            {
              label: '0 till 100 kilometer i timmen',
              value: '4.7 sek'
            }
          ],
          dimensions: [
            {
              label: 'L\u00e4ngd',
              value: '4606 mm'
            },
            {
              label: 'H\u00f6jd',
              value: '1479 mm'
            },
            {
              label: 'Bredd inkl backspeglar',
              value: '1985 mm'
            },
            {
              label: 'Hjulbas',
              value: '2735 mm'
            },
            {
              label: 'Markfrig\u00e5ng',
              value: '151 mm (146 mm med Performance-paketet)'
            },
            {
              label: 'L\u00e4ngd',
              value: '4606 mm'
            },
            {
              label: 'H\u00f6jd',
              value: '1479 mm'
            },
            {
              label: 'Bredd inkl backspeglar',
              value: '1985 mm'
            },
            {
              label: 'Hjulbas',
              value: '2735 mm'
            },
            {
              label: 'Markfrig\u00e5ng',
              value: '151 mm (146 mm med Performance-paketet)'
            }
          ]
        },
        car_delivery_preferencies: {
          delivery_option: null,
          has_insurance: null,
          personal_id_number: null
        },
        car_factory_order: {
          meta_order_number: '12345',
          factory_order_number: null,
          vin: 'LPSWSEKFEML135065',
          chassis_number: null,
          actual_vista_status_point: null,
          car_factory_order_statuses: [],
          factory_code: null,
          last_day_for_specification_change_date: null,
          planned_factory_complete_day: null,
          accepted_planned_build_date: null,
          accepted_planned_delivery_date: '2020-07-01',
          current_planned_build_date: null,
          current_planned_delivery_date: '2020-07-01',
          current_dealer_delivery_date: '2020-07-01',
          pds_date: null
        }
      },
      {
        orderline_id: 7491,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 1000,
        unit_vat: 0,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: 'A00492',
        extra_orderline_type: 'Package',
        extra_product_type: 'Mat',
        name: 'Front and rear passenger floor mats',
        quantity: 1,
        package_id: null,
        package_name: null,
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7492,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 1000,
        unit_vat: 200,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: '32261146',
        extra_orderline_type: 'Product',
        extra_product_type: 'Mat',
        name: 'Front and rear passenger floor mats',
        quantity: 1,
        package_id: 'A00492',
        package_name: 'Front and rear passenger floor mats',
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7493,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 30000,
        unit_vat: 0,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: 'A01164',
        extra_orderline_type: 'Package',
        extra_product_type: 'Winter Wheels',
        name: '20\u201d nordic friction',
        quantity: 1,
        package_id: null,
        package_name: null,
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7494,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 30000,
        unit_vat: 6000,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: '32147833',
        extra_orderline_type: 'Product',
        extra_product_type: 'Winter Wheels',
        name: 'Complete Winter, 20", Friction, Nordic',
        quantity: 1,
        package_id: 'A01164',
        package_name: '20\u201d nordic friction',
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7495,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 2750,
        unit_vat: 0,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: 'A00379',
        extra_orderline_type: 'Package',
        extra_product_type: 'Child seat',
        name: 'Booster seat',
        quantity: 1,
        package_id: null,
        package_name: null,
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7496,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 1125,
        unit_vat: 225,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: '31470488',
        extra_orderline_type: 'Product',
        extra_product_type: 'Child seat',
        name: 'Child seat, Booster cushion',
        quantity: 1,
        package_id: 'A00379',
        package_name: 'Booster seat',
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7497,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 1625,
        unit_vat: 325,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: '31470519',
        extra_orderline_type: 'Product',
        extra_product_type: 'Child seat',
        name: 'Backrest',
        quantity: 1,
        package_id: 'A00379',
        package_name: 'Booster seat',
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7498,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 3000,
        unit_vat: 0,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: 'WS00001',
        extra_orderline_type: 'Package',
        extra_product_type: 'Tire Hotel',
        name: 'Wheel storage',
        quantity: 1,
        package_id: null,
        package_name: null,
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      },
      {
        orderline_id: 7499,
        created_at: '2020-04-15T13:33:59Z',
        updated_at: '2020-04-29T12:05:26Z',
        orderline_status: 'confirmed',
        unit_price: 3000,
        unit_vat: 600,
        vat_rate: 25,
        delivery_date: null,
        orderline_cancellation_reason: null,
        orderline_cancellation_note: null,
        orderline_return_reason: null,
        orderline_return_note: null,
        orderline_type: 'Extra',
        delivery_address: {
          da_address_line_1: null,
          da_address_line_2: null,
          da_zip_code: null,
          da_city: null,
          da_district: null,
          da_province: null,
          da_country_code: null
        },
        part_nr: 'PWW1WC',
        extra_orderline_type: 'Product',
        extra_product_type: 'Tire Hotel',
        name: 'Wheel change/one year of storage',
        quantity: 1,
        package_id: 'WS00001',
        package_name: 'Wheel storage',
        workorder: {
          workorder_id: null,
          workorder_link: null,
          workorder_creation_date: null,
          customer_contact: null,
          tire_hotel_location: null,
          delivery_date: null
        },
        extra_delivery_preferencies: {
          wallboxinstallation_delivery_preferencies: {
            installation_at: null,
            installation_partner: null,
            pre_inspection_at: null
          },
          tirehotel_delivery_preferencies: {
            transport_preference: null,
            preferred_location: null,
            preferred_location_name: null,
            preferred_parma_code: null,
            partner_id: null
          },
          wintertire_delivery_preferencies: {
            wants_together_with_car: null,
            wants_transport_from_dealer: null
          }
        }
      }
    ],
    remarketed_car: {
      features: null
    }
  }
};

export const getOrderMock = (
  input: GetOrderParams,
  result: OrderContract = mockOrderResponse
): {
  request: { query: DocumentNode; variables: GetOrderParams };
  result: { data: GetOrderResponse };
} => ({
  request: {
    query: GET_ORDER,
    variables: input
  },
  result: {
    data: {
      order: JSON.stringify(result)
    }
  }
});
