import { InMemoryCache } from '@apollo/client';
import { unionBy } from 'lodash';
import { SearchResponseData } from '~apollo/queries/getHandovers';
import { SearchBatchData } from './queries/getSearchBatches';
import { GetSurveyResponsesResponseData } from './queries/getSurveyResponsesWithComments';
import { isNonEmptyString } from '~helpers/stringHelper';
import { GetGlobalNewsData } from './queries/getGlobalNews';

export const cache = new InMemoryCache({
  // addTypenames needs to match the value provided to MockProvider, which should be false.
  // https://www.apollographql.com/docs/react/development-testing/testing/#setting-addtypename
  // https://github.com/apollographql/react-apollo/issues/3730#issuecomment-562226208
  addTypename: process.env.NODE_ENV !== 'test',
  typePolicies: {
    Handover: {
      keyFields: ['orderNumber']
    },
    SurveyResponse: {
      keyFields: ['vin']
    },
    Query: {
      fields: {
        searchHandover: {
          keyArgs: ['input', ['searchValue', 'sortings', 'filters', 'aggregations']],
          merge(existing: SearchResponseData | undefined, incoming: SearchResponseData, { args }) {
            if (args && typeof args.input.from === 'number' && args.input.from > 0) {
              const existingHits = existing?.hits || [];
              return { ...incoming, hits: unionBy(existingHits, incoming.hits, '__ref') };
            } else return incoming;
          }
        },
        searchBatch: {
          keyArgs: ['input', ['searchValue', 'sortings', 'filters', 'searchConfig']],
          merge(existing: SearchBatchData | undefined, incoming: SearchBatchData, { args }) {
            if (args && typeof args.input.from === 'number' && args.input.from > 0) {
              const existingHits = existing?.hits || [];
              return { ...incoming, hits: unionBy(existingHits, incoming.hits, '__ref') };
            } else return incoming;
          }
        },
        getStaticImage: {
          keyArgs: ['input', ['filterKey', 'locale']]
        },
        getSurveyResponsesWithComments: {
          keyArgs: ['input', ['surveyId', 'locationId', 'isBackwardsQuery']],
          merge(
            existing: GetSurveyResponsesResponseData | undefined,
            incoming: GetSurveyResponsesResponseData,
            { args }
          ) {
            if (
              args &&
              typeof args.input.paginationToken === 'string' &&
              isNonEmptyString(args.input.paginationToken)
            ) {
              const existingHits = existing?.responses || [];
              return { ...incoming, responses: unionBy(existingHits, incoming.responses, '__ref') };
            } else return incoming;
          }
        },
        getGlobalNewsSummaries: {
          keyArgs: ['input', ['locale', 'categoryIds', 'marketIds', 'locationTypeIds']],
          merge(existing: GetGlobalNewsData | undefined, incoming: GetGlobalNewsData) {
            const existingHits = existing?.globalNewsSummaries || [];
            return {
              ...incoming,
              globalNewsSummaries: unionBy(existingHits, incoming.globalNewsSummaries, '__ref')
            };
          }
        }
      }
    }
  }
});
