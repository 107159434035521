export const EN_DASH = '–';

export enum FinancialProducts {
  CASH = 'Cash',
  LOAN = 'Loan',
  LOAN_BALLOON = 'Loanballoon',
  LOAN_BALLOON_GFV = 'LoanBalloonGFV',
  LEASING = 'Leasing',
  SUBSCRIPTION = 'Subscription',
  PERSONAL_LOAN = 'Personal Loan',
  PERSONAL_LEASING = 'Personal Leasing',
  FINANCIAL_LEASE = 'FinancialLease',
  OPERATIONAL_LEASE = 'OperationalLease'
}
export enum NoteType {
  BOOKING_CANCELLATION = 'BookingCancellation',
  FLEET = 'Fleet',
  CUSTOMER_CARE = 'CustomerCare'
}
export enum BookingStatuses {
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  DISABLED = 'DISABLED',
  SCHEDULE = 'SCHEDULE',
  CANCELLED = 'CANCELLED',
  REMOVED = 'REMOVED'
}

export enum FinalPaymentBuyerStatuses {
  FULLY_PAID = 'fully_paid',
  NOT_PAID = 'not_paid'
}

//TODO Should be changed to 'true' and 'false'
export enum RegistrationResponsibilities {
  CUSTOMER = 'Customer',
  AGENCY = 'Agency'
}

export enum HandoverStates {
  IN_TRANSIT = 'IN_TRANSIT',
  PREPARATION = 'PREPARATION',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
  PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED'
}

export enum OrderTypes {
  ORDER = 'order',
  FLEET = 'fleet',
  PRECONFIGURED = 'preconfigured',
  REMARKETED = 'remarketed'
}

export enum LockStates {
  UNLOCKED = 'unlocked',
  LOCKED = 'locked',
  MANUALLY_UNLOCKED = 'manually_unlocked'
}

export enum HandoverMethods {
  COLLECT = 'collect',
  COLLECT_ESSENTIAL = 'collect_essential',
  HOME_DELIVERY = 'home_delivery',
  HOME_DELIVERY_ESSENTIAL = 'home_delivery_essential',
  PAID_HOME_DELIVERY = 'paid_home_delivery',
  BULK_COLLECT = 'bulk_collect',
  BULK_CUSTOMER_DELIVERY = 'bulk_customer_delivery'
}

export enum ExtrasOrdered {
  ALL_COMPLETED,
  ALL_ORDERED,
  PARTLY_ORDERED,
  NOT_ORDERED,
  NOTHING_TO_ORDER
}

export enum SingleOptionsStatuses {
  NOT_ORDERED = 'NOT_ORDERED',
  IN_PROGRESS = 'IN_PROGRESS',
  ORDERED = 'ORDERED',
  COMPLETED = 'COMPLETED'
}

// According to POMS example: 2022-03-17 https://polestarjira.atlassian.net/wiki/spaces/DEV/pages/608141377/Example+events
export enum deRegistrationInformationStatus {
  registrated = 'registrated',
  contact_customer = 'ContactCustomer',
  document_received = 'DocumentReceived',
  waiting_for_documents = 'WaitingForDocuments',
  waiting_for_approval_from_client = 'WaitingForApproval',
  waiting_for_zb2 = 'WaitingForZb2',
  documents_verified = 'DocumentsVerified',
  documents_ok = 'DocumentsOk',
  vehicle_registration = 'VehicleRegistration',
  vehicle_registered = 'VehicleRegistered',
  waiting_for_appointment = 'WaitingForAppointment',
  appointment_selected = 'AppointmentSelected',
  delivered = 'Delivered',
  self_registration = 'SelfRegistration'
}

export enum MarketAdaptionStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED'
}

export enum FormTypes {
  CoC = 'CoC',
  Finance = 'Finance',
  Identification = 'Identification',
  Registration = 'Registration',
  RegistrationMandate = 'RegistrationMandate',
  SignerEmail = 'SignerEmail',
  SignerName = 'SignerName',
  LetterOfAuthority = 'LetterOfAuthority',
  InternetConsent = 'InternetConsent',
  HandoverPreparation = 'HandoverPreparation'
}

export enum ExtraProductTypes {
  WINTER_WHEELS = 'Winter Wheels'
}

export enum PreparationOptionalSteps {
  ORDER_CONFIRMED = 'order_confirmed',
  WHEELS_OK = 'wheels_ok',
  RANK_MARK = 'rank_mark_added',
  RANK_MARK_ORDERED = 'rank_mark_ordered',
  THIRD_PARTY_DELIVERY_ACCEPTANCE = 'third_party_delivery_acceptance',
  GENERAL_VEHICLE_CHECK = 'general_vehicle_check',
  MUA_UNSIGNED = 'mua_unsigned_printed'
}

export enum PdfTemplate {
  DELIVERY_ACCEPTANCE = 'HOPDeliveryAcceptance',
  BULK_DELIVERY_ACCEPTANCE = 'HOPBulkDeliveryAcceptance'
}

export enum Editions {
  BST230 = 'BST Edition 230',
  BST270 = 'BST Edition 270'
}

export enum VehiclePreparationStatuses {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

export enum HandoverPreparationStatuses {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

export enum CalendarViewType {
  WORKING_WEEK = 'WORKING_WEEK',
  WEEK = 'WEEK',
  DAY = 'DAY'
}

export enum CarModel {
  PS2 = 'Polestar 2',
  PS3 = 'Polestar 3',
  PS4 = 'Polestar 4'
}

export enum ExtraStatus {
  NOT_ORDERED = 'Not ordered',
  ORDERED = 'Ordered',
  INSTALLED_IN_FACTORY = 'Installed in factory',
  INSTALLATION_CONFIRMED = 'Installation confirmed'
}
