import { useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';
import { convertExtrasContract, ExtraPart } from '~app/helpers/handoverConverter';
import { userLanguageToPapiLanguage } from '~app/helpers/marketHelper';
import { isNonEmptyString } from '~app/helpers/stringHelper';
import useAuthState from './useAuthState';
import {
  Extra,
  ExtrasContract,
  GET_HANDOVER_EXTRAS,
  GetHandoverExtrasRequest,
  GetHandoverExtrasResponse,
  SalesItem
} from '~apollo/queries/getHandoverExtras';
import { getTowbarRelatedParts } from '~helpers/extrasHelper';
import { EN_DASH } from '~enums';
import { HandoverTableData } from '~apollo/queries/getHandoverExtended';

const useExtrasData = (orderNumber: string, languageOverride?: string) => {
  const { userLanguage } = useAuthState();
  const [extraData, setExtraData] = useState<ReturnType<typeof convertExtrasContract>>(new Map());
  const [salesItems, setSalesItem] = useState<Array<SalesItem>>([]);
  const [extras, setExtras] = useState<Array<Extra>>([]);

  const acceptLanguage = userLanguageToPapiLanguage(languageOverride ?? userLanguage);

  const { loading } = useQuery<GetHandoverExtrasResponse, GetHandoverExtrasRequest>(
    GET_HANDOVER_EXTRAS,
    {
      variables: {
        input: {
          acceptLanguage,
          orderNumber
        }
      },
      fetchPolicy: 'cache-first',
      onCompleted: data => {
        // Deprecated way of handling data
        const extras = convertExtrasContract(JSON.parse(data.extras.extrasJson));
        const entries = Array.from(extras.entries());
        setExtraData(prev => new Map([...Array.from(prev.entries()), ...entries]));

        // Raw data, separated into sales categories and extras (parts)
        const extrasContract = JSON.parse(data.extras.extrasJson) as ExtrasContract;
        setSalesItem(
          extrasContract.data?.sales_categories.flatMap(categories => categories.sales_items) ?? []
        );

        setExtras(extrasContract.data?.extras ?? []);
      },
      onError: error => {
        console.error(`getExtras orderNumber:${orderNumber} error: `, error);
      }
    }
  );

  const getPartName = useCallback(
    (parentSalesItemId: number | null, part: ExtraPart) => {
      const salesItem = salesItems.find(item => item.id === parentSalesItemId);
      if (salesItem) {
        const salesItemParts = extras.filter(x => x.id && salesItem.extras.includes(x.id));
        const partName = salesItemParts.find(x => x.article_number === part.id)?.title;
        return isNonEmptyString(partName) ? partName : part.fallbackName;
      }

      return part.fallbackName;
    },
    [extras, salesItems]
  );

  const getSalesItemExtras = useCallback(
    (handover: HandoverTableData, salesItemId: number | null | undefined) => {
      const salesItem = salesItems.find(item => item.id === salesItemId);
      const parts: Array<Extra> = [];
      if (salesItem) {
        const salesItemExtra = extras.find(x => x.id === salesItem.id);
        salesItemExtra && parts.push(salesItemExtra);
        parts.push(...extras.filter(x => x.id && salesItem.extras.includes(x.id)));
        parts.push(
          ...getTowbarRelatedParts(
            handover.orderData?.modelYear ?? '',
            handover.orderData?.chassisNumber ?? '',
            salesItem.article_number
          )
        );
      }
      return parts;
    },
    [extras, salesItems]
  );

  const getExtraName = useCallback(
    (extra: ExtraPart) =>
      extra.salesItemId
        ? extraData.get(extra.salesItemId)?.name ?? extra.fallbackName
        : extra.fallbackName,
    [extraData]
  );

  const getSalesItemName = useCallback(
    (salesItemId: number | null | undefined, fallbackName: string | undefined | null) =>
      salesItemId
        ? extraData.get(salesItemId)?.name ?? fallbackName ?? EN_DASH
        : fallbackName ?? EN_DASH,
    [extraData]
  );

  return {
    isLoading: loading,
    localeData: extraData,
    getExtraName,
    getPartName,
    getSalesItemName,
    getSalesItemExtras
  };
};

export default useExtrasData;
