import React, { useMemo, useState } from 'react';
import {
  ExtraPackage,
  GET_HANDOVER_EXTENDED,
  GetHandoverExtendedParams,
  GetHandoverExtendedResponse,
  HandoverTableData,
  getMainOrder,
  getRelatedOrders
} from '~apollo/queries/getHandoverExtended';
import {
  CheckboxField,
  Heading,
  List,
  Orientations,
  Spacer,
  Spacings
} from '@polestar/component-warehouse-react';
import OrderHeaderInfo from '../Notes/OrderHeaderInfo';
import { cloneDeep } from 'lodash';
import useExtrasData from '~hooks/useExtrasData';
import { ExtraPackageCard } from '../ExtraPackageCard';
import HandoverContextProvider from '~contexts/Handover';
import useAuthState from '~hooks/useAuthState';
import { convertOrder } from '~helpers/handoverConverter';
import { useQuery } from '@apollo/client';
import SaveExtrasFooter from './SaveExtrasFooter';
import { OrderContract } from '~apollo/queries/getOrder';
import { LightDivider } from '~helpers/styles';
import useTableColumns from '~hooks/useTableColumns';
import { TableCells } from '~enums/OrderTable';
import useDatoState from '~hooks/useDatoState';
import { getVehiclePreparationExtras, isItemOrdered } from '~root/src/app/helpers/extrasHelper';

const ExtrasDrawerContent = ({
  handover,
  extras
}: {
  handover: HandoverTableData;
  extras: Array<ExtraPackage>;
}) => {
  const { text } = useDatoState();
  const { userLocation, userLanguage } = useAuthState();
  const [savingExtras, setSavingExtras] = useState(false);

  const [orderedExtras, setOrdererdExtras] = useState<Array<ExtraPackage>>(
    cloneDeep(extras.filter(x => isItemOrdered(x.orderlineStatus)))
  );
  const [initialExtras, setInitialExtras] = useState(
    orderedExtras
      .map(extra => extra.salesItemId)
      .sort()
      .toString()
  );
  const { getSalesItemName, getSalesItemExtras, isLoading } = useExtrasData(handover.orderNumber);

  const [mainOrder, setMainOrder] = useState<OrderContract>();
  const [relatedOrders, setRelatedOrders] = useState<Array<OrderContract>>([]);
  const [convertedOrder, setConvertedOrder] = useState<ReturnType<typeof convertOrder>>();

  const { data: { response: { handover: handoverExtended = undefined } = {} } = {}, loading } =
    useQuery<GetHandoverExtendedResponse, GetHandoverExtendedParams>(GET_HANDOVER_EXTENDED, {
      variables: { input: { orderNumber: handover.orderNumber } },
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        const orders = data.response.orders;
        const mainOrder = getMainOrder(orders, handover.orderNumber);
        const relatedOrders = getRelatedOrders(orders, handover.orderNumber);
        const convertedOrder = convertOrder(mainOrder, relatedOrders);
        setMainOrder(mainOrder);
        setRelatedOrders(relatedOrders);
        setConvertedOrder(convertedOrder);

        if (data.response.handover.extraPackages) {
          const updatedOrderedExtras = cloneDeep(
            data.response.handover.extraPackages.filter(x => isItemOrdered(x.orderlineStatus))
          );
          setOrdererdExtras(updatedOrderedExtras);

          const updatedInitialExtras = updatedOrderedExtras
            .map(extra => extra.salesItemId)
            .sort()
            .toString();
          setInitialExtras(updatedInitialExtras);
        }
      }
    });

  const showSaveFooter = useMemo(() => {
    const currentSelection = orderedExtras
      .map(extra => extra.salesItemId)
      .sort()
      .toString();
    return currentSelection !== initialExtras;
  }, [orderedExtras, initialExtras]);

  const handoverToUse = handoverExtended ?? handover;

  const extrasToUse = getVehiclePreparationExtras(handoverToUse);

  const { renderCell } = useTableColumns(userLanguage, handoverToUse);

  return (
    <>
      <Heading level={2}>{text('Extras')}</Heading>
      <Spacer />
      {renderCell({ name: TableCells.EXTRAS_STATUS_CELL })}
      <Spacer />
      <OrderHeaderInfo handover={handover} />
      <List spacing={Spacings.xSmall} separator={<LightDivider spacing={Spacings.medium} />}>
        {extrasToUse.map(extra => (
          <React.Fragment key={`extra-${extra.salesItemId}`}>
            <ExtraPackageCard
              handover={handoverToUse}
              extra={extra}
              name={getSalesItemName(extra.salesItemId, extra.fallbackName)}
              parts={getSalesItemExtras(handoverToUse, extra.salesItemId)
                .map(part => part.article_number)
                .join(', ')}
              orientation={Orientations.vertical}
              loading={isLoading}
            />
            <Spacer spacing={Spacings.xSmall} />
            <CheckboxField
              disabled={savingExtras || loading}
              checked={orderedExtras.some(x => x.partNumber === extra.partNumber)}
              label={getSalesItemName(extra.salesItemId, extra.fallbackName)}
              onChange={() => {
                setOrdererdExtras(prev => {
                  if (orderedExtras.some(x => x.partNumber === extra.partNumber)) {
                    return prev.filter(x => x.partNumber !== extra.partNumber);
                  } else {
                    const tmp = cloneDeep(prev);
                    tmp.push(extra);
                    return tmp;
                  }
                });
              }}
            />
          </React.Fragment>
        ))}
      </List>
      <Spacer spacing={Spacings.xxxLarge} />
      {mainOrder && convertedOrder && (
        <HandoverContextProvider
          handover={handoverToUse}
          order={convertedOrder}
          location={userLocation}
          fullOrder={mainOrder}
          relatedOrders={relatedOrders}
        >
          <SaveExtrasFooter
            showFooter={showSaveFooter}
            handover={handoverToUse}
            orderedExtras={orderedExtras}
            activeExtras={extrasToUse}
            isSavingExtras={savingExtras}
            setIsSavingExtras={setSavingExtras}
          />
        </HandoverContextProvider>
      )}
    </>
  );
};

export default ExtrasDrawerContent;
