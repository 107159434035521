export enum StaticTexts {
  HMM = 'Hmmm...',
  NO_ACCESS_TO_LOCATION = 'Sorry, you have no access to this location',
  NO_ACCESS_TO_ORDER = 'Sorry, you have no access to this order',
  NO_ACCESS_TO_HOP = 'Sorry, you have no access to the Handover Portal',
  ERROR_HAS_OCCURRED = 'Sorry, an error has occurred',
  ERROR_MESSAGE_FOR_SUPPORT = 'Error message for support:',
  LOG_OUT = 'Log out',
  WHATS_NEW = "What's new",
  VIEW_ALL = 'View all',
  NEW_FEATURES = 'New features',
  UPDATES = 'Updates',
  CHANGELOG = 'Changelog',
  RELEASE_NOTE = 'Release note',
  REFRESH_FOR_NEW_FEATURES = 'Refresh for new features',
  RELOAD = 'Reload the page',
  GO_HOME = 'Go home',
  PAGE_NOT_FOUND = 'Page not found',
  PAGE_NOT_FOUND_MESSAGE = 'The page you are looking for doesn’t exist or another error occurred',
  IF_UNABLE_TO_LOGIN = 'If you are unable to log in, it may be due to your account being deactivated after 90 days of inactivity, or your user account may have been removed for another reason.',
  FOLLOW_INSTRUCTIONS_FOR_ACCESS = 'To request access, please follow the instructions below:',
  ACCESS_INSTRUCTION_1 = 'If you work at a Handover or Space location: Contact your manager or market contact for support.',
  ACCESS_INSTRUCTION_2 = 'If you work at Polestar at the market or HQ level: Please submit a request for access through the ',
  ACCESS_INSTRUCTION_3 = 'If you encounter any other issues: Feel free to contact us via email at '
}
