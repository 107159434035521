import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from '@polestar/component-warehouse-react';

import { CustomerSummary, Handover } from '~app/apollo/queries/getHandoverExtended';
import { DealerLocation } from '~app/apollo/queries/getLocation';
import {
  UpdateHandoverResponse,
  UpdateHandoverRequest,
  UPDATE_HANDOVER,
  UpdateHandoverRequestData
} from '~app/apollo/mutations/updateHandover';
import useDatoState from '~app/hooks/useDatoState';
import { convertOrder } from '~app/helpers/handoverConverter';

import HandoverContext from './context';
import { OrderContract } from '~app/apollo/queries/getOrder';
import DocumentsContextProvider from '../Documents';
import useAuthState from '../../hooks/useAuthState';

type Partner = {
  capabilities: Array<String | null> | null;
  service_partner_po_number: string | null;
  parma_code: string | null;
  location_code: string | null;
  partner_id: string | null;
  name: string | null;
  phone: string | null;
  address_line_1: string | null;
  zip_code: string | null;
  city: string | null;
  country_code: string | null;
};

type AdditionalDelivery = {
  delivery_id: number | null;
  type: string | null;
  handover_method: string | null;
  status: string | null;
  completed_at: string | null;
  updated_in_vista_at: string | null;
  photo_document_ref: string | null;
  identification_type: string | null;
  identification_number: string | null;
  signed_document_ref: string | null;
  partner?: Partner | null;
  partners?: Array<Partner> | null;
};

export type SummerWheelData = {
  transportOrderNumber?: string | null;
};

export type WheelData = {
  summerWheelData?: SummerWheelData;
  needsWinterWheelMounting?: boolean | null;
  wantsTogetherWithCar?: boolean | null;
  deliverWinterWheelsSeparately?: boolean | null;
  additionalDelivery?: AdditionalDelivery | null;
  tireHotelPartner?: Partner | null;
  tireStoragePartner?: Partner | null;
};

type Props = {
  handover: Handover;
  location: DealerLocation;
  children: React.ReactNode;
  order: ReturnType<typeof convertOrder>;
  fullOrder?: OrderContract;
  relatedOrders?: Array<OrderContract>;
  customerSummary?: CustomerSummary;
  shouldFetchVehicleImages?: boolean;
};

const HandoverContextProvider = (props: Props) => {
  const { userLanguage } = useAuthState();
  const { text } = useDatoState();
  const [handover, setHandover] = useState<Handover>(props.handover);
  const [handoverLocation, setHandoverLocation] = useState<DealerLocation>(props.location);
  const [order, setOrder] = useState<ReturnType<typeof convertOrder>>(props.order);
  const [fullOrder] = useState<OrderContract | undefined>(props.fullOrder ?? undefined);
  const [relatedOrders, setRelatedOrders] = useState<Array<OrderContract>>(
    props.relatedOrders ?? []
  );
  const [isDeliveryFormChanged, setIsDeliveryFormChanged] = useState(false);
  const { addSnackbar } = useSnackbar();
  const [updateSuccessMsg, setUpdateSuccessMsg] = useState('UpdateHandoverSuccess');
  const extras = useMemo(
    () =>
      order.extras.filter(extra =>
        handover.extraPackages?.find(x => x.orderlineId === extra.orderLineId)
      ),
    [handover.extraPackages, order.extras]
  );
  const hiddenExtras = order.extras.filter(
    extra => !handover.extraPackages?.find(x => x.orderlineId === extra.orderLineId)
  );
  const [hideSuccessSnackbar, setHideSuccessSnackbar] = useState(false);

  //if needsWinterWheelMount=true, wantsTogetherWithCar refers to summer tyres
  const wheels = useMemo<WheelData>(() => {
    //if there is a related order with product type "Winter Wheels" use that to get wheel data
    const rawWheelRelatedOrder = relatedOrders?.find(order =>
      order.data?.orderlines?.some(x => x.extra_product_type === 'Winter Wheels')
    );

    let wheelRelatedOrder;
    if (rawWheelRelatedOrder) {
      wheelRelatedOrder = convertOrder(rawWheelRelatedOrder);
    }

    const summerWheelData = {
      transportOrderNumber: order.additionalDeliveries?.find(d => d.type === 'summerwheel_delivery')
        ? order.orderNumber
        : rawWheelRelatedOrder?.data?.order_no
    } as SummerWheelData;

    const wheelExtra = wheelRelatedOrder
      ? wheelRelatedOrder.extras.find(x => x.extraProductType === 'Winter Wheels')
      : extras.find(x => x.extraProductType === 'Winter Wheels');

    const needsWinterWheelMount = wheelRelatedOrder
      ? wheelRelatedOrder.vehicle.winterTireMount
      : order.vehicle.winterTireMount;

    const wheelAdditionalDelivery = wheelRelatedOrder
      ? wheelRelatedOrder.additionalDeliveries?.find(x => x.handover_method === 'tire_hotel')
      : order.additionalDeliveries?.find(x => x.handover_method === 'tire_hotel');

    const tireHotelPartner = wheelRelatedOrder
      ? wheelRelatedOrder.additionalDeliveries
          ?.find(x => x.partners?.find(x => x.capabilities?.includes('tire_hotel')))
          ?.partners?.find(x => x.capabilities?.includes('tire_hotel'))
      : order.additionalDeliveries
          ?.find(x => x.partners?.find(x => x.capabilities?.includes('tire_hotel')))
          ?.partners?.find(x => x.capabilities?.includes('tire_hotel'));

    const tireStoragePartner = wheelRelatedOrder
      ? wheelRelatedOrder.additionalDeliveries
          ?.find(x => x.partners?.find(x => x.capabilities?.includes('tire_storage')))
          ?.partners?.find(x => x.capabilities?.includes('tire_storage'))
      : order.additionalDeliveries
          ?.find(x => x.partners?.find(x => x.capabilities?.includes('tire_storage')))
          ?.partners?.find(x => x.capabilities?.includes('tire_storage'));

    const wantsTogetherWithCar =
      wheelExtra?.deliveryPreferencies?.wintertire_delivery_preferencies
        ?.wants_together_with_car === true
        ? true
        : wheelExtra?.deliveryPreferencies?.wintertire_delivery_preferencies
            ?.wants_together_with_car === false
        ? false
        : undefined;

    // If needsWinterWheelMounting === true, wantsTogetherWithCar refers to summerWheels.
    const deliverWinterWheelsSeparately = !needsWinterWheelMount && wantsTogetherWithCar === false;

    return {
      summerWheelData: summerWheelData,
      needsWinterWheelMounting: needsWinterWheelMount,
      wantsTogetherWithCar: wantsTogetherWithCar && wantsTogetherWithCar !== null,
      deliverWinterWheelsSeparately: deliverWinterWheelsSeparately,
      additionalDelivery: wheelAdditionalDelivery,
      tireHotelPartner: tireHotelPartner,
      tireStoragePartner: tireStoragePartner
    };
  }, [
    relatedOrders,
    order.additionalDeliveries,
    order.orderNumber,
    order.vehicle.winterTireMount,
    extras
  ]);

  useEffect(() => {
    setHandoverLocation(props.location);
  }, [props.location]);

  const [updateHandoverMutation, { error: updateError, data: updatedResp, loading: isUpdating }] =
    useMutation<UpdateHandoverResponse, UpdateHandoverRequest>(UPDATE_HANDOVER);

  useEffect(() => {
    if (updatedResp != null) {
      setHandover(updatedResp.updatedHandover);
    }
  }, [updatedResp]);

  useEffect(() => {
    if (typeof updateError !== 'undefined') {
      addSnackbar({
        content: text('UpdateHandoverError'),
        error: true,
        closeOnClick: true
      });
    }
    if (updatedResp != null) {
      if (!hideSuccessSnackbar) {
        addSnackbar({
          content: text(updateSuccessMsg, 'update-handover-success'),
          error: false,
          closeOnClick: true
        });
      }
    }
  }, [addSnackbar, text, updateError, updatedResp]);

  const updateHandover = useCallback(
    async (
      value: UpdateHandoverRequestData,
      onCompleted?: Function,
      onError?: Function,
      successText = 'UpdateHandoverSuccess',
      hideSuccessSnackbar = false
    ) => {
      setUpdateSuccessMsg(successText);
      setHideSuccessSnackbar(hideSuccessSnackbar);
      try {
        await updateHandoverMutation({
          variables: { input: { orderNumber: handover.orderNumber, ...value } }
        });
        if (onCompleted) {
          onCompleted();
        }
      } catch (e) {
        if (onError) {
          onError();
        }
        console.error(e);
      }
    },
    [handover, updateHandoverMutation]
  );

  const setHandoverOrder = useCallback((order: ReturnType<typeof convertOrder>) => {
    setOrder(order);
  }, []);

  const setNewHandover = useCallback((handover: Handover) => {
    setHandover(handover);
  }, []);

  const providerValue = {
    handover,
    handoverLocation,
    updateHandover,
    handoverOrder: order,
    setHandoverOrder,
    setHandover: setNewHandover,
    handoverTimezone: handoverLocation.timezone,
    isLoading: isUpdating,
    error: updateError,
    fullOrder,
    relatedOrders,
    setRelatedOrders,
    isDeliveryFormChanged,
    setIsDeliveryFormChanged,
    extras,
    hiddenExtras,
    wheels,
    customerSummary: props.customerSummary
  };

  return (
    <DocumentsContextProvider
      orderNumber={handover.orderNumber}
      marketId={handover.marketId}
      userLanguage={userLanguage}
      batchId={handover.batch?.id}
    >
      <HandoverContext.Provider {...props} value={providerValue} />
    </DocumentsContextProvider>
  );
};

export { HandoverContext };
export default HandoverContextProvider;
