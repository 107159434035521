// Application specific configuration (environment based)
// We expose a function instead of an object to make sure dotenv has been called before the config itself is defined
// Otherwise all process.env.XXX are undefined.
const getRuntimeConfig = () => ({
  application: {
    // namespace: process.env.APP_NAMESPACE || 'handover',
    environment: process.env.ENVIRONMENT,
    // Domain for authentication cookie
    domain: process.env.APP_DOMAIN || '',
    enabledSendWheelsSe: process.env.ENABLE_SEND_WHEELS_SE
  },
  auth: {
    authorityUrl: process.env.AUTH_AUTHORITY_URI,
    tenantId: process.env.AUTH_TENANT_ID,
    clientId: process.env.AUTH_CLIENT_ID,
    operatorServicesApplicationId: process.env.AUTH_OPERATOR_SERVICES_APPLICATION_ID
  },
  api: {
    // Define parameters specific to the API (environment-based or not)
    baseUrl: process.env.API_BASE_URL || '',
    operatorServicesUrl: process.env.API_GLOBAL_OPERATOR_SERVICES_BASE_URL || ''
  },
  dato: {
    // Define parameters specific to the content API (environment-based or not)
    assetUrl: process.env.DATO_ASSET_BASE_URL,
    baseUrl: process.env.DATO_BASE_URL || '',
    previewUrl: process.env.DATO_PREVIEW_BASE_URL || '',
    token: process.env.DATO_TOKEN
  },
  docusign: {
    templateId: process.env.DOCUSIGN_TEMPLATE_ID || '',
    volvoTemplateId: process.env.DOCUSIGN_TEMPLATE_ID_VOLVO || ''
  },
  build: {
    // Define parameters specific to the build environment (most likely environment-based)
    mode: process.env.NODE_ENV, // "development" | "production"
    // crossOriginLoading attribute for webpack (we may want to switch between 'anonymous' and 'use-credentials' based on the stage)
    // https://webpack.js.org/configuration/output/#outputcrossoriginloading
    crossOrigin: process.env.CROSS_ORIGIN || 'anonymous'
  },
  servicenow: {
    ticketUrl: process.env.SERVICE_NOW_TICKET_URL,
    accessUrl: process.env.SERVICE_NOW_ACCESS_URL
  },
  features: {
    enableActivityFlow: process.env.ENABLE_ACTIVITY_FLOW,
    enableNorthAmericaDeliveryMethod: process.env.ENABLE_NORTH_AMERICA_DELIVERY_METHOD,
    enableNewVistaCodes: process.env.ENABLE_NEW_VISTA_CODES
  }
});

export default getRuntimeConfig;
