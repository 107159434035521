import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import {
  PolestarApp,
  Themes,
  Text,
  Spacer,
  Spacings,
  Section,
  PrimaryButton,
  Accordion,
  AccordionSection,
  ThemeTypes,
  Heading,
  Dialog,
  Alignments,
  BulletList,
  Paragraph,
  GridColumn,
  Grid
} from '@polestar/component-warehouse-react';
import Menu from '~app/components/Base/Menu';
import useThemeState from '~app/hooks/useThemeState';
import AuthContextProvider from '~app/contexts/AuthContext';
import { LOGIN, LoginResponse } from '~app/apollo/queries/login';
import { GetRolesResponse, GET_ROLES, MARKET_USER, Role } from '~app/apollo/queries/getRoles';
import {
  countryToLocaleMapper,
  countryToLangMapper,
  userLanguageToISOLocale
} from '~app/helpers/marketHelper';
import {
  GET_LOCATION,
  GetLocationResponse,
  GetLocationParams
} from '~app/apollo/queries/getLocation';
import Header from '~app/components/Base/Header';
import { generateUrl } from '~root/src/routes/helpers';
import {
  UPDATE_USER_SETTINGS,
  UpdateUserSettingsResponse,
  UpdateUserSettingsRequest
} from '~app/apollo/mutations/updateUserSettings';
import { isNonEmptyString } from '~app/helpers/stringHelper';
import { Msal2 } from '~root/src/config/msal';
import LoadingPortal from '~app/components/Shared/LoadingPortal';
import UpdateDialogArea from '~app/components/Base/UpdateDialogArea';
import OrderEventDrawer from './components/Base/OrderEventDrawer';
import { InternalPolestarApp } from '@polestar/component-warehouse-react/internal';
import OrderTableContextProvider from './contexts/OrderTable';
import { StaticTexts } from './enums/StaticText';
import { usePersistedState } from '~app/hooks/usePersistedState';
import ChangeLocationDialog from '~app/components/Shared/ChangeLocationDialog';
import Footer from '~app/components/Shared/ChangeLocationDialog/Footer';
import { Path } from '~root/src/routes/definition';
import gtmHelper from '~helpers/gtmHelper';
import { isLimitedUser } from '~helpers/userHelper';
import DrawerContextProvider from './contexts/Drawer';
import getRuntimeConfig from '~root/src/config';
import CustomOutlet from './components/CustomOutlet';
import ErrorBoundary from './components/Shared/ErrorBoundary';

export const SW_UPDATE_EVENT = 'sw.updated';

const App = () => {
  const navigate = useNavigate();
  const { isLight } = useThemeState();
  const location = useLocation();
  const config = getRuntimeConfig();
  const [isShowingEventNotificationDrawer, setIsShowingNotificationDrawer] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { data: loginResponse, error: loginError } = useQuery<LoginResponse>(LOGIN, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      gtmHelper.pushLoginSuccessful(data);

      const currentLocation = data.login.settings?.currentLocationId;
      if (isNonEmptyString(currentLocation)) {
        getLocation({
          variables: {
            input: { id: currentLocation }
          }
        });
      } else if (data.login.locationIds && data.login.locationIds.length > 0) {
        updateUserSettings({
          variables: {
            input: {
              userProfileId: data.login.id,
              currentLocationId: data.login.locationIds[0],
              currentMarketId: data.login.settings?.currentMarketId
            }
          }
        });
        getLocation({
          variables: {
            input: { id: data.login.locationIds[0] }
          }
        });
        return;
      } else if (isNonEmptyString(data.login.settings?.currentMarketId)) {
        // When a user has selected an 'All Market' we don't need to open the location dialog
        return;
      } else navigate(generateUrl(Path.SET_LOCATIONS));
    }
  });

  useEffect(() => {
    const title = getTabsTitle(config.application.environment);
    document.title = title;
  }, []);

  const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false);
  const closeDialog = useCallback(() => setIsLocationDialogOpen(false), []);
  const [locationsLoaded, setLocationsLoaded] = useState(false);
  const [showArchivedLocations, setShowArchivedLocations] = usePersistedState(
    'showArchivedLocations',
    false
  );
  const [userRole, setUserRole] = useState<Role>();

  const {
    data: allRoles,
    loading: isRolesLoading,
    error: rolesError
  } = useQuery<GetRolesResponse>(GET_ROLES, {
    skip: typeof loginResponse === 'undefined',
    onCompleted: data => {
      const role = data.roles.find(x => x.id === loginResponse?.login.roleId);
      if (role === undefined) {
        setErrorMessage(`User role: ${loginResponse?.login.roleId} not found`);
      } else {
        setUserRole(role);
      }
    }
  });

  const [getLocation, { data: userLocation, loading: isLocationLoading, error: locationError }] =
    useLazyQuery<GetLocationResponse, GetLocationParams>(GET_LOCATION, {
      fetchPolicy: 'network-only'
    });

  const [updateUserSettings, { loading: isUpdatingUser }] = useMutation<
    UpdateUserSettingsResponse,
    UpdateUserSettingsRequest
  >(UPDATE_USER_SETTINGS, { fetchPolicy: 'network-only' });

  const isMomentView = location.pathname.split('/').includes('moment');

  useEffect(() => {
    if (
      typeof loginResponse === 'undefined' ||
      typeof allRoles === 'undefined' ||
      typeof userRole === 'undefined' ||
      typeof userLocation === 'undefined'
    ) {
      return;
    }
    if (
      userRole.order <= MARKET_USER &&
      (typeof loginResponse.login.marketIds === 'undefined' ||
        loginResponse.login.marketIds.length === 0)
    ) {
      setErrorMessage(`User with role: ${loginResponse.login.roleId} must have market`);
      return;
    }
    if (
      userRole.order < MARKET_USER &&
      (typeof loginResponse.login.locationIds === 'undefined' ||
        loginResponse.login.locationIds.length === 0)
    ) {
      setErrorMessage(`User with role: ${loginResponse.login.roleId} must have location`);
      return;
    }
    if (
      userRole.order <= MARKET_USER &&
      !loginResponse.login.marketIds?.some(x => x === userLocation.location?.marketId) &&
      !isLimitedUser(userRole, loginResponse.login.marketIds, loginResponse.login.locationIds)
    ) {
      navigate(generateUrl(Path.SET_LOCATIONS));
      return;
    } else if (
      userRole.order < MARKET_USER &&
      !loginResponse.login.locationIds?.some(x => x === userLocation.location?.id) &&
      !isLimitedUser(userRole, loginResponse.login.marketIds, loginResponse.login.locationIds)
    ) {
      navigate(generateUrl(Path.SET_LOCATIONS));
      return;
    }
  }, [allRoles, isRolesLoading, loginResponse, userLocation, userRole]);

  useEffect(() => {
    if (locationError) {
      navigate(generateUrl(Path.SET_LOCATIONS));
    }
  }, [locationError]);

  const error = loginError || rolesError;
  //Texts are not translated since we are not logged in
  if (error || errorMessage) {
    // TODO: Figure out overarching error handling plan
    return (
      <PolestarApp theme={ThemeTypes.light}>
        <Section>
          <Spacer spacing={Spacings.large} />
          {loginError?.message === 'No such user found' ? (
            <>
              <Grid>
                <GridColumn span={{ mobile: 4, tablet: 8, desktop: 8 }}>
                  <Heading level={2}>{StaticTexts.NO_ACCESS_TO_HOP}</Heading>
                  <Spacer spacing={Spacings.large} />
                  {Msal2.getInstance().getAllAccounts().length > 0 && (
                    <>
                      <Text>
                        {`You are logged in as ${
                          Msal2.getInstance().getAllAccounts()[0].username
                        }. If this is not the right account, please log out and try again. `}
                      </Text>
                      <Spacer />
                      {StaticTexts.IF_UNABLE_TO_LOGIN}
                      <Spacer />
                      {StaticTexts.FOLLOW_INSTRUCTIONS_FOR_ACCESS}
                      <Spacer />
                      <BulletList compact>
                        <Paragraph>{StaticTexts.ACCESS_INSTRUCTION_1}</Paragraph>
                        <Paragraph>
                          {StaticTexts.ACCESS_INSTRUCTION_2}
                          <a href={config.servicenow.accessUrl}>ServiceNow form</a>
                          {'.'}
                        </Paragraph>
                        <Paragraph>
                          {StaticTexts.ACCESS_INSTRUCTION_3}
                          <a href="mailto:handover.support@polestar.com">
                            handover.support@polestar.com
                          </a>
                        </Paragraph>
                      </BulletList>
                      <Spacer />
                      <PrimaryButton type="button" onClick={() => Msal2.getInstance().logout()}>
                        {StaticTexts.LOG_OUT}
                      </PrimaryButton>
                      <Spacer spacing={Spacings.xxxLarge} />
                    </>
                  )}
                </GridColumn>
              </Grid>
            </>
          ) : (
            <>
              <Heading level={2}>Sorry, an error has occured.</Heading>
              <Accordion>
                <AccordionSection
                  title={StaticTexts.ERROR_MESSAGE_FOR_SUPPORT}
                  data-testid="error-message"
                >
                  <Heading level={3}>
                    {isNonEmptyString(errorMessage) ? errorMessage : error?.message}
                  </Heading>
                  <Spacer spacing={Spacings.large} />
                  <Text>{JSON.stringify(error)}</Text>
                </AccordionSection>
              </Accordion>
            </>
          )}
        </Section>
      </PolestarApp>
    );
  }

  if (
    typeof loginResponse === 'undefined' ||
    userRole === undefined ||
    isRolesLoading ||
    isLocationLoading ||
    isUpdatingUser
  ) {
    return <LoadingPortal />;
  }

  return (
    <AuthContextProvider
      loggedInUser={{
        id: loginResponse.login.id,
        userId: loginResponse.login.userId ?? '',
        name: loginResponse.login.name,
        givenName: loginResponse.login.givenName,
        email: loginResponse.login.email,
        role: userRole,
        privileges: loginResponse.login.privileges,
        appData: loginResponse.login.appData,
        calendarView: loginResponse.login.settings?.calendarView
      }}
      language={loginResponse.login.settings?.locale ?? countryToLangMapper('en')}
      isoLocale={userLanguageToISOLocale(
        loginResponse.login.settings?.locale ?? countryToLocaleMapper('en')
      )}
      locale={countryToLocaleMapper(userLocation?.location?.marketId ?? 'en')}
      currentMarket={loginResponse.login.settings?.currentMarketId}
      currentLocation={
        userLocation?.location ?? {
          id: 'temp',
          name: 'temp',
          address: '',
          marketId: 'GB',
          timezone: 'UTC',
          city: '',
          archived: false
        }
      }
      marketIds={loginResponse.login.marketIds ?? []}
      isMarketView={isNonEmptyString(loginResponse.login.settings?.currentMarketId)}
      locations={loginResponse.login.locationIds ?? []}
    >
      <InternalPolestarApp
        theme={!isMomentView || isLight ? ThemeTypes.light : ThemeTypes.dark}
        themes={{ light: Themes.light, dark: Themes.dark }}
      >
        <DrawerContextProvider>
          {!isMomentView &&
            !isLimitedUser(
              userRole,
              loginResponse.login.marketIds,
              loginResponse.login.locationIds
            ) && (
              <Header
                setIsShowingNotificationDrawer={setIsShowingNotificationDrawer}
                userRole={userRole.order}
                userLocations={loginResponse.login.locationIds ?? []}
                setIsLocationDialogOpen={setIsLocationDialogOpen}
              />
            )}
          <OrderTableContextProvider>
            <Menu />
            <Dialog
              detached
              fullscreen={false}
              width="895px"
              withCloseButton
              open={isLocationDialogOpen}
              onClose={closeDialog}
              footer={
                locationsLoaded && (
                  <Footer
                    showArchivedLocations={showArchivedLocations}
                    setShowArchivedLocations={setShowArchivedLocations}
                  />
                )
              }
              alignment={Alignments.top}
              style={{
                maxHeight: '95vh'
              }}
            >
              {isLocationDialogOpen && (
                <ChangeLocationDialog
                  onClose={closeDialog}
                  showArchivedLocations={showArchivedLocations}
                  loaded={state => {
                    setLocationsLoaded(state);
                  }}
                />
              )}
            </Dialog>
            <OrderEventDrawer
              isShowingEventNotificationDrawer={isShowingEventNotificationDrawer}
              setIsShowingNotificationDrawer={setIsShowingNotificationDrawer}
            />
            <ErrorBoundary>
              <>
                {!location.pathname.includes('calendar') &&
                  !location.pathname.includes('moment') && <UpdateDialogArea />}
                <CustomOutlet />
              </>
            </ErrorBoundary>
          </OrderTableContextProvider>
        </DrawerContextProvider>
      </InternalPolestarApp>
    </AuthContextProvider>
  );
};

export default App;

function getTabsTitle(environment: string | undefined) {
  switch (environment) {
    case 'dev' || 'dev-global':
      return '(Dev) Handover Portal';
    case 'staging' || 'staging-global':
      return '(Staging) Handover Portal';
    case 'production-global':
      return 'Handover Portal';
    default:
      return 'Handover Portal';
  }
}
