import { Colors } from '@polestar/component-warehouse-react';
import { ExtraPackage, HandoverTableData } from '~apollo/queries/getHandoverExtended';
import { Extra } from '~apollo/queries/getHandoverExtras';
import { ExtraProductTypes, ExtraStatus } from '~enums';
import { OrderTableCell, checkIcon, minusIcon } from '~enums/Cells';
import {
  LEGACY_TRAILER_MODULE,
  UPDATED_TRAILER_MODULE_OLD_25,
  UPDATED_TRAILER_MODULE_24,
  UPDATED_TRAILER_MODULE_25
} from './handoverConverter';
import { isStatusPreOrdered } from './orderHelper';
import { InternalColors } from '@polestar/component-warehouse-react/internal';
import { OrderlineStatus } from '../apollo/queries/getOrder';

export const filterWheelsThatShouldNotBeOrdered = (
  handover: HandoverTableData,
  extraPackages: Array<ExtraPackage>
) => {
  let extras = extraPackages && extraPackages?.length > 0 ? [...extraPackages] : [];

  const needsWinterWheelMount = handover.orderData?.needsWinterWheelMounting;
  const wantsTogetherWithCar = extraPackages?.find(
    x => x.productType === ExtraProductTypes.WINTER_WHEELS
  )?.winterTireWantsTogetherWithCar;

  if (
    !needsWinterWheelMount &&
    !wantsTogetherWithCar &&
    wantsTogetherWithCar !== null &&
    wantsTogetherWithCar !== undefined
  ) {
    extras = extras.filter(x => x.productType !== ExtraProductTypes.WINTER_WHEELS);
  }

  return extras;
};

export const getVehiclePreparationExtras = (handover: HandoverTableData) => {
  let extras =
    handover.extraPackages && handover.extraPackages.length > 0 ? [...handover.extraPackages] : [];

  extras = filterWheelsThatShouldNotBeOrdered(handover, extras);

  return extras && extras.length > 0
    ? extras.filter(
        extra =>
          extra.orderlineStatus !== 'cancelled' && extra.productType !== 'Tax' && extra.orderlineId
      )
    : [];
};

const towbarCommonProps = {
  title: 'Towbar',
  description: 'Towbar description',
  product_type: 'Towbar',
  sortorder: 1,
  requires: [],
  incompatible: []
};

/**
 * Trailer module id is based on model year and chassis number
 * https://polestarjira.atlassian.net/browse/VH-4712
 */

export const getTrailerModuleId = (modelYear: string | null, chassisNumber: string | null) => {
  if (modelYear && Number(modelYear) === 2024)
    return chassisNumber && Number(chassisNumber) >= 241237
      ? UPDATED_TRAILER_MODULE_24
      : LEGACY_TRAILER_MODULE;
  else if (modelYear && Number(modelYear) >= 2025)
    return chassisNumber && Number(chassisNumber) >= 241238
      ? UPDATED_TRAILER_MODULE_25
      : UPDATED_TRAILER_MODULE_OLD_25;
  else return LEGACY_TRAILER_MODULE;
};

// Get parts that POMS does not add as extrsa/parts
export const getTowbarRelatedParts = (
  modelYear: string,
  chassisNumber: string,
  articleNumber: string
) => {
  const parts: Array<Extra> = [];
  switch (articleNumber) {
    case 'A00463':
      parts.push(
        ...[
          {
            ...towbarCommonProps,
            id: null,
            article_number: getTrailerModuleId(modelYear, chassisNumber),
            title: 'Trailer module'
          },
          {
            ...towbarCommonProps,
            id: null,
            article_number: '31650476',
            title: 'Bracket, trailer module'
          },
          {
            ...towbarCommonProps,
            id: null,
            article_number: '31399328',
            title: 'Operation button panel'
          },
          {
            ...towbarCommonProps,
            id: null,
            article_number: '32296317',
            title: 'SW- TRM ADD 13S'
          }
        ]
      );
      break;
    case 'A01407':
      parts.push(
        ...[
          { ...towbarCommonProps, id: null, article_number: '32351966', title: 'Trailer module' },
          { ...towbarCommonProps, id: null, article_number: '32417110', title: 'Tape for WAE' },
          { ...towbarCommonProps, id: null, article_number: '32414297', title: 'SW- TRM ADD 13S' }
        ]
      );
      break;
    case 'A01408':
      parts.push(
        ...[
          { ...towbarCommonProps, id: null, article_number: '32351966', title: 'Trailer module' },
          { ...towbarCommonProps, id: null, article_number: '32417110', title: 'Tape for WAE' },
          { ...towbarCommonProps, id: null, article_number: '32266344', title: 'Switch pack' },
          { ...towbarCommonProps, id: null, article_number: '32414297', title: 'SW- TRM ADD 13S' }
        ]
      );
      break;
    case 'A01409':
      parts.push(
        ...[
          { ...towbarCommonProps, id: null, article_number: '32351966', title: 'Trailer module' },
          { ...towbarCommonProps, id: null, article_number: '32417110', title: 'Tape for WAE' },
          {
            ...towbarCommonProps,
            id: null,
            article_number: '32132981',
            title: 'Ball holder w 2" ball'
          },
          { ...towbarCommonProps, id: null, article_number: '32296756', title: 'Bag jack' },
          { ...towbarCommonProps, id: null, article_number: '32414296', title: 'SW- TRM ADD 4/7S' }
        ]
      );
      break;
    case 'A01520':
      parts.push(
        ...[
          {
            ...towbarCommonProps,
            id: null,
            article_number: '80038421',
            title: 'Cable harness (TRM to towbar)'
          },
          { ...towbarCommonProps, id: null, article_number: '80014770', title: 'Trailer module' },
          { ...towbarCommonProps, id: null, article_number: '31200010', title: 'Fastener kit' },
          { ...towbarCommonProps, id: null, article_number: '80001861', title: 'Switch pack' },
          { ...towbarCommonProps, id: null, article_number: '80019136', title: 'SW- TRM ADD 13S' }
        ]
      );
      break;
    case 'A01521':
      parts.push(
        ...[
          {
            ...towbarCommonProps,
            id: null,
            article_number: '80009167',
            title: 'Cable harness (TRM to towbar)'
          },
          { ...towbarCommonProps, id: null, article_number: '80014770', title: 'Trailer module' },
          { ...towbarCommonProps, id: null, article_number: '31207209', title: 'Fastener kit' },
          { ...towbarCommonProps, id: null, article_number: '80001861', title: 'Switch pack' },
          { ...towbarCommonProps, id: null, article_number: '32296756', title: 'Bag jack' },
          { ...towbarCommonProps, id: null, article_number: '80019135', title: 'SW- TRM ADD 4/7S' }
        ]
      );
      break;
  }

  return parts;
};

export const isItemOrdered = (orderlineStatus: OrderlineStatus | undefined) => {
  return orderlineStatus ? !isStatusPreOrdered(orderlineStatus) : false;
};

export const getExtraStatus = (handover: HandoverTableData, extra: ExtraPackage) => {
  const cell: OrderTableCell = {
    value: ExtraStatus.NOT_ORDERED,
    valueString: ExtraStatus.NOT_ORDERED,
    borderColor: Colors.agatheGrey,
    icon: minusIcon
  };
  if (isItemOrdered(extra.orderlineStatus)) {
    if (extra.isMandatory) {
      cell.value = cell.valueString = ExtraStatus.INSTALLED_IN_FACTORY;
      cell.borderColor = InternalColors.systemYellow;
      cell.icon = checkIcon;
    } else {
      cell.value = cell.valueString = ExtraStatus.ORDERED;
      cell.borderColor = InternalColors.systemYellow;
      cell.icon = checkIcon;
    }
  }
  if (extra.orderlineStatus === 'ready_for_delivery' || extra.orderlineStatus === 'delivered') {
    cell.value = cell.valueString = ExtraStatus.INSTALLATION_CONFIRMED;
    cell.borderColor = InternalColors.systemGreen;
    cell.icon = checkIcon;
  }

  return cell;
};
