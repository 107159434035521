export const localeToLangMapper = (marketLocale?: string) => {
  if (typeof marketLocale === 'undefined') {
    return 'en';
  }
  // Markets: en, sv-SE, nb_NO, fr-BE, nl-BE, en-CA, fr-CA, zh-CN, de-DE, nl-NL, en-GB, en-US, fr-CH, de-CH, it-CH, en-CH
  // DATO:    en, sv_SE, nb_NO, fr_BE, nl_BE, en_CA, fr_CA, zh_CN, de_DE, nl_NL, en_GB, en_US, fr_CH, de_CH, it_CH, en_CH
  return marketLocale.replace('-', '_');
};

export const userLanguageToISOLocale = (language: string) => {
  // User Language:                 en, sv_SE, nb_NO, fr_BE, nl_BE, en_CA, fr_CA, zh_CN, de_DE, nl_NL, en_GB, en_US, fr_CH, de_CH, it_CH, en_CH
  // ISO Locale for ie. DatePicker: en, sv-SE, nb_NO, fr-BE, nl-BE, en-CA, fr-CA, zh-CN, de-DE, nl-NL, en-GB, en-US, fr-CH, de-CH, it-CH, en-CH
  return language.replace('_', '-');
};

// ISO 639-1 language shortcodes, used for date-holidays dependency
export const userLanguageToIso639 = (language: string) => {
  switch (language) {
    case 'en':
      return language;
    case 'fr-BE':
    case 'nl_BE':
    case 'en_CA':
    case 'en_AU':
    case 'en_GB':
    case 'en-IE':
    case 'fr_CA':
    case 'fr-LU':
    case 'fr_CH':
    case 'de_CH':
    case 'it_CH':
    case 'en_CH':
    case 'de-AT':
    case 'ko-KR':
      return language.split('_')[0];
    default:
      return language.split('_')[1].toLowerCase();
  }
};

export const localeToCountryMapper = (marketLocale: string) => {
  const [lang, country] = marketLocale.split('-');

  if (typeof country === 'undefined') {
    return 'en';
  }
  return country;
};

export const countryToLocaleMapper = (countryCode: string) => {
  if (countryCode.toLowerCase() === 'en') {
    return 'en-GB';
  }
  // !These locales and countries are the only ones we support with MomentJs and MomentTimezone, see webpack.prod.js
  switch (countryCode.toLowerCase()) {
    case 'se':
      return 'sv-SE';
    case 'no':
      return 'nb-NO';
    case 'be':
      return 'nl-BE';
    case 'de':
      return 'de-DE';
    case 'nl':
      return 'nl-NL';
    case 'gb':
      return 'en-GB';
    case 'ch':
      return 'de-CH';
    case 'fi':
      return 'fi-FI';
    case 'dk':
      return 'da-DK';
    case 'at':
      return 'de-AT';
    case 'au':
      return 'en-AU';
    case 'kr':
      return 'ko-KR';
    case 'lu':
      return 'fr-LU';
    case 'ie':
      return 'en-IE';
    case 'es':
      return 'es-ES';
    case 'pt':
      return 'pt-PT';
    case 'us':
      return 'en-US';
    case 'it':
      return 'it-IT';
    case 'ca':
      return 'en-CA';
    default:
      return 'en-GB';
  }
};

export const countryToLangMapper = (countryCode: string) => {
  return localeToLangMapper(countryToLocaleMapper(countryCode));
};

export const milesMarkets = ['gb', 'us'];

//Papi/Poms helpers
// Usally the market/language should be only two characters when its the same value on either side of the "-", eg:
// 'es-ES' -> 'es'       'pt-PT' -> 'pt'      'NL-BE' -> 'nl-be' (see README for more info)

export const userLanguageToPapiLanguage = (userLanguage: string) => {
  // Supported translations in papi
  // en, nl-be, fr-be, en-ch, fr-ch, it-ch, de-ch, en-ca, fr-ca, zh-cn, de, nl, no, se, uk, us
  const lowerLang = userLanguage.toLowerCase();
  switch (lowerLang) {
    case 'en':
    case 'nl_be':
    case 'fr_be':
    case 'en_ch':
    case 'fr_ch':
    case 'it_ch':
    case 'de_ch':
    case 'zh_cn':
    case 'da_dk':
    case 'de_at':
    case 'en_au':
    case 'ko_kr':
    case 'fr_lu':
    case 'en_ie':
    case 'en_ca':
    case 'fr_ca':
      return lowerLang.replace('_', '-');
    case 'de_de':
    case 'nl_nl':
    case 'nb_no':
    case 'sv_se':
    case 'fi_fi':
    case 'es_es':
    case 'pt_pt':
    case 'en_us':
    case 'it_it':
      return lowerLang.split('_')[1];
    case 'en_gb':
      return 'uk';
    default:
      return 'en';
  }
};
export const pomsMarketToPapiMarket = (pomsMarket: string) => {
  // Supported markets in papi (same as papi language except 'en')
  // nl-be, fr-be, en-ch, fr-ch, it-ch, de-ch, en-ca, fr-ca, zh-cn, de, nl, no, se, uk, us
  const lowerMarket = pomsMarket.toLowerCase();
  switch (lowerMarket) {
    case 'gb':
      return 'uk';
    case 'be':
      return 'nl-be';
    case 'ch':
      return 'de-ch'; // Probably the default market since en-ch didn't give any results in staging, wating for confirmation from Pär Andrén 2021-02-04
    case 'lu':
      return 'fr-lu'; // ISO Lang. code: fr-LU
    case 'ca':
      return 'en-ca';
    case 'uk': // GB is probably used in poms but just to be safe /HL
    case 'de':
    case 'nl':
    case 'se':
    case 'no':
    case 'fi':
    case 'dk':
    case 'at':
    case 'au':
    case 'kr':
    case 'ie':
    case 'es':
    case 'pt':
    case 'us':
    case 'it':
      return lowerMarket;
    default:
      return 'se';
  }
};

//VH-2567
export const userLanguageToVdmsLocale = (userLang: string) => {
  const lowerLang = userLang.toLowerCase();
  switch (lowerLang) {
    case 'sv_se':
    case 'da_dk':
    case 'de_de':
    case 'de_at':
    case 'nb_no':
    case 'it_it':
    case 'nl_nl':
    case 'es_es':
    case 'pt_pt':
    case 'fi_fi':
    case 'ko_kr':
    case 'en_au':
    case 'en_ie':
    case 'en_ch':
      return lowerLang.split('_')[1];
    case 'fr_ch':
    case 'it_ch':
    case 'de_ch':
    case 'nl_be':
    case 'fr_be':
    case 'fr_lu':
      return lowerLang.replace('_', '-');
    case 'en':
      return 'us';
    case 'en_gb':
      return 'uk';
    default:
      return 'us';
  }
};
