import { HandoverMethods, HandoverStates } from '../enums';
import {
  ANY_OPTION,
  HandoverFilter,
  HandoverFilterAttrib,
  HandoverFilterType
} from '~enums/Filters';
import { prepFromDateParamInUtc, prepToDateParamInUtc } from '~helpers/dateHelper';
import { isNonEmptyString } from './stringHelper';
import { DealerLocation } from '~apollo/queries/getLocation';
import { SearchFilter } from '~apollo/queries/getHandovers';
import { BatchSearchKeywordField } from '~apollo/queries/getSearchBatches';

export const doesFilterAtrribAllowMultiple = (attrib: HandoverFilterAttrib) => {
  let res = true;
  if (
    attrib === HandoverFilterAttrib.REGISTRATION_STATE ||
    attrib === HandoverFilterAttrib.BATCH_ID
  ) {
    res = false;
  }
  return res;
};

export const doesFilterOperatorAllowMultiple = (operator?: HandoverFilterType) =>
  operator === HandoverFilterType.IS || operator === HandoverFilterType.IS_NOT;

//Adds 'paid_home_delivery' when 'home_delivery' is applied
export const handleHandoverMethodFilters = (filters: Array<HandoverFilter>) => {
  let updatedFilters: Array<HandoverFilter> = filters;

  updatedFilters = updatedFilters.map(filter =>
    filter.attrib === HandoverFilterAttrib.HANDOVER_METHOD &&
    filter.values?.includes(HandoverMethods.HOME_DELIVERY)
      ? {
          ...filter,
          values: [...filter.values, HandoverMethods.PAID_HOME_DELIVERY]
        }
      : filter
  );
  return updatedFilters;
};

//Remove orders that are 'DONE' 'CANCELLED' or 'PARTIALLY_DELIVERED' if HandoverFilterAttrib.HandoverState is not active
export const handleHandoverStateFilters = (filters: Array<HandoverFilter>) => {
  let updatedFilters: Array<HandoverFilter> = filters;
  if (!filters.find(filter => filter?.attrib === HandoverFilterAttrib.HANDOVER_STATE)) {
    updatedFilters.push({
      attrib: HandoverFilterAttrib.HANDOVER_STATE,
      type: HandoverFilterType.IS_NOT,
      values: [HandoverStates.DONE, HandoverStates.CANCELLED, HandoverStates.PARTIALLY_DELIVERED]
    });
  }
  return updatedFilters;
};

// Use '0' instead of 'Zero' for HandoverFilterAttrib.NOTES_COUNT
export const handleNotesCountFilters = (filters: Array<HandoverFilter>) => {
  let updatedFilters: Array<HandoverFilter> = filters;
  updatedFilters = filters.map(filter =>
    filter.attrib === HandoverFilterAttrib.NOTES_COUNT
      ? {
          ...filter,
          values: ['0']
        }
      : filter
  );
  return updatedFilters;
};

// Use IS_SET instead of value ANY_OPTION
export const handleAnyAsFilterValue = (filters: Array<HandoverFilter>) => {
  let updatedFilters: Array<HandoverFilter> = filters;
  updatedFilters = filters.map(filter =>
    filter.values?.includes(ANY_OPTION)
      ? {
          ...filter,
          type: HandoverFilterType.IS_SET,
          values: undefined
        }
      : filter
  );
  return updatedFilters;
};

export const handleDatesFilters = (
  key: HandoverFilterAttrib,
  dateFrom: string,
  dateTo: string,
  timezone: string
): Array<SearchFilter> => {
  const filters = [];

  if (isNonEmptyString(dateFrom.toString())) {
    filters.push({
      field: key,
      type: HandoverFilterType.GTE,
      values: [prepFromDateParamInUtc(new Date(dateFrom), timezone)]
    });
  }

  if (isNonEmptyString(dateTo.toString())) {
    filters.push({
      field: key,
      type: HandoverFilterType.LTE,
      values: [prepToDateParamInUtc(new Date(dateTo), timezone)]
    });
  }

  return filters;
};

// based on if it is a market view (all Market is selected) the id will represent either locationId or marketId
export const handleLocationFilters = (isMarketView: boolean, id: string) => {
  return isMarketView
    ? {
        field: HandoverFilterAttrib.MARKET_ID,
        type: HandoverFilterType.IS,
        values: [id]
      }
    : {
        field: HandoverFilterAttrib.LOCATION_ID,
        type: HandoverFilterType.IS,
        values: [id]
      };
};

export const handleHandoverLocationFilters = (isMarketView: boolean, id: string) => {
  return isMarketView
    ? {
        field: HandoverFilterAttrib.MARKET_ID,
        type: HandoverFilterType.IS,
        values: [id]
      }
    : {
        field: HandoverFilterAttrib.HANDOVER_LOCATION,
        type: HandoverFilterType.IS,
        values: [id]
      };
};

export const getRelevantBrandStatuses = (values: Array<string> = [], type?: HandoverFilterType) => {
  let relevantBrandStatuses: Array<string> = [];

  if (values.length === 0) return relevantBrandStatuses;

  values.forEach(value => {
    switch (value) {
      case '11200': // '11200' is Factory Complete
        relevantBrandStatuses.push(value);
        relevantBrandStatuses.push('11207');
        relevantBrandStatuses.push('11216');
        break;
      case '14080': // '14080' is Departed to Zeebrugge
        relevantBrandStatuses.push(value);
        relevantBrandStatuses.push('14082');
        break;
      case '16000': // '16000' is In transit to dealer
        relevantBrandStatuses.push(value);
        relevantBrandStatuses.push('16080');
        break;
      case '15505': // TODO remove after POMS updates all orders
      case '16005':
        relevantBrandStatuses.push(value);
        relevantBrandStatuses.push('15550');
        break;
      default:
        relevantBrandStatuses.push(value);
    }
  });

  relevantBrandStatuses = relevantBrandStatuses.sort((a, b) => parseInt(a) - parseInt(b));
  if (type === 'GT' || type === 'LTE') {
    // Get the highest value
    return [relevantBrandStatuses[relevantBrandStatuses.length - 1] ?? ''];
  } else if (type === 'LT' || type === 'GTE') {
    // Get the lowest value
    return [relevantBrandStatuses[0] ?? ''];
  }
  return relevantBrandStatuses;
};

// Add duplicate filter names for VISTA brand status
export const handleVistaBrandStatusFilters = (filters: Array<HandoverFilter>) => {
  let updatedFilters: Array<HandoverFilter> = filters;
  updatedFilters = filters.map(filter =>
    filter.attrib === HandoverFilterAttrib.ACTUAL_BRAND_STATUS_POINT_NUMBER
      ? {
          ...filter,
          values: getRelevantBrandStatuses(filter.values, filter.type)
        }
      : filter
  );
  return updatedFilters;
};

export const getDefaultDeliveryPlanningAggregationFilters = (
  userLocation: DealerLocation,
  isMarketView: boolean,
  userMarketId?: string
) => [
  handleLocationFilters(isMarketView, isMarketView ? userMarketId ?? '' : userLocation.id),
  {
    field: HandoverFilterAttrib.HANDOVER_STATE,
    type: HandoverFilterType.IS_NOT,
    values: [HandoverStates.CANCELLED]
  }
];

export const getDefaultBulkAggregationFilters = (
  userLocationId: string,
  isMarketView: boolean,
  userMarketId?: string,
  includeDelivered = false,
  includeCancelled = false
) => {
  const handoverStateValues = [HandoverStates.IN_TRANSIT, HandoverStates.PREPARATION];
  if (includeDelivered) {
    handoverStateValues.push(HandoverStates.DONE);
    handoverStateValues.push(HandoverStates.PARTIALLY_DELIVERED);
  }
  if (includeCancelled) {
    handoverStateValues.push(HandoverStates.CANCELLED);
  }

  return [
    handleHandoverLocationFilters(isMarketView, isMarketView ? userMarketId ?? '' : userLocationId),
    {
      field: HandoverFilterAttrib.HANDOVER_STATE,
      type: HandoverFilterType.IS,
      values: handoverStateValues
    },
    {
      field: HandoverFilterAttrib.HANDOVER_METHOD,
      type: HandoverFilterType.IS,
      values: [HandoverMethods.BULK_COLLECT, HandoverMethods.BULK_CUSTOMER_DELIVERY]
    }
  ];
};

export const handleLocationBatchFilters = (isMarketView: boolean, id: string) => {
  return isMarketView
    ? {
        field: BatchSearchKeywordField.MARKET_ID,
        type: HandoverFilterType.IS,
        values: [id]
      }
    : {
        field: BatchSearchKeywordField.LOCATION_ID,
        type: HandoverFilterType.IS,
        values: [id]
      };
};

export const handleBatchIDFilters = (filters: Array<HandoverFilter>) => {
  let updatedFilters: Array<HandoverFilter> = filters;

  filters.map(filter => {
    if (filter.attrib === HandoverFilterAttrib.BATCH_ID) {
      if (filter.values?.includes('N/A')) {
        updatedFilters.push({
          attrib: HandoverFilterAttrib.HANDOVER_METHOD,
          type: HandoverFilterType.IS_NOT,
          values: [HandoverMethods.BULK_COLLECT, HandoverMethods.BULK_CUSTOMER_DELIVERY]
        });
      } else if (filter.values?.includes('NotAssignedYet')) {
        updatedFilters.push({
          attrib: HandoverFilterAttrib.BATCH_ID,
          type: HandoverFilterType.IS_NOT_SET
        });
        updatedFilters.push({
          attrib: HandoverFilterAttrib.HANDOVER_METHOD,
          type: HandoverFilterType.IS,
          values: [HandoverMethods.BULK_COLLECT, HandoverMethods.BULK_CUSTOMER_DELIVERY]
        });
      } else {
        updatedFilters.push({
          attrib: HandoverFilterAttrib.BATCH_ID,
          type: HandoverFilterType.IS_SET
        });
      }
      updatedFilters = updatedFilters.filter(fil => fil !== filter);
    }
  });

  return updatedFilters;
};
