import React, { Component, ErrorInfo, ReactNode } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import GenericError from './GenericError';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import ChunkLoadError from './ChunkLoadError';

interface Props {
  children: ReactNode;
}

interface State {
  error: Error | null;
}

/**
 * There is no hook that replaces getDerivedStateFromError or componentDidCatch as of right now.
 * > "There are no Hook equivalents for these methods yet, but they will be added soon."
 * @see https://reactjs.org/docs/hooks-faq.html#how-do-lifecycle-methods-correspond-to-hooks
 */
class ErrorBoundary extends Component<Props, State> {
  state: State = {
    error: null
  };

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorBoundaryError = new Error(error.message);
    errorBoundaryError.name = `React ErrorBoundary ${error.name}`;
    if (errorInfo.componentStack) {
      errorBoundaryError.stack = errorInfo.componentStack;
    }

    datadogRum.addError(errorBoundaryError);
  }

  render() {
    const { error } = this.state;

    return error ? <GenericError error={error} /> : this.props.children;
  }
}

const withRouterErrors = (Component: typeof ErrorBoundary) => {
  // eslint-disable-next-line func-names
  return function WrapperComponent(props: Props) {
    const error = useRouteError();

    if (error) {
      const errorTest = error as Error;
      if (errorTest.name && errorTest.name.includes('ChunkLoadError')) {
        return <ChunkLoadError />;
      }
    }

    if (isRouteErrorResponse(error)) {
      return <GenericError error={error.statusText} />;
    }

    return <Component {...props} />;
  };
};

export default withRouterErrors(ErrorBoundary);
