import { Dispatch, SetStateAction, createContext } from 'react';
import { ApolloError } from '@apollo/client';
import { DealerLocation } from '~app/apollo/queries/getLocation';
import { UpdateHandoverRequestData } from '~app/apollo/mutations/updateHandover';
import { CustomerSummary, Handover } from '~app/apollo/queries/getHandoverExtended';
import { convertOrder, ExtraType } from '~app/helpers/handoverConverter';
import { OrderContract } from '~app/apollo/queries/getOrder';
import { WheelData } from './index';

type HandoverContext = {
  handover: Handover;
  handoverLocation: DealerLocation;
  handoverOrder: ReturnType<typeof convertOrder>;
  fullOrder?: OrderContract;
  relatedOrders: Array<OrderContract>;
  setRelatedOrders: Dispatch<SetStateAction<OrderContract[]>>;
  handoverTimezone: string;
  updateHandover: (
    input: UpdateHandoverRequestData,
    onCompleted?: Function,
    onError?: Function,
    successText?: string,
    hideSuccessSnackbar?: boolean
  ) => void;
  setHandoverOrder: (order: ReturnType<typeof convertOrder>) => void;
  setHandover: (handover: Handover) => void;
  isLoading: boolean;
  error: ApolloError | undefined;
  isDeliveryFormChanged: boolean;
  setIsDeliveryFormChanged: (value: boolean) => void;
  extras: Array<ExtraType>;
  hiddenExtras: Array<ExtraType>;
  wheels: WheelData;
  customerSummary?: CustomerSummary | null;
};

export default createContext<HandoverContext | undefined>(undefined);
